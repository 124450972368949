import React from 'react';
import ScriptTag from 'react-script-tag';

// @material-ui/core components
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Hidden from '@material-ui/core/Hidden';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';

// Components
import CardView from '../../components/CardView';
import TextView from '../../components/TextView';
import { config, sendCompraResumenGerente, sendCompraResumenCliente } from '../../Api';
import { planes, cities } from '../../ArraysDB';
import firebase, { fireDb } from '../../Firebase';

const styles = {
  buttonTextWhite: {
    color: 'white',
    textTransform: 'capitalize',
    fontSize: '3rem'
  },
  buttonTextPrimary: {
    color: '#35647D',
    textTransform: 'capitalize',
    fontSize: '1rem'
  }
};

const money = new Intl.NumberFormat('es-MX', {
  style: 'currency',
  currency: 'MXN',
});

function getFormattedDate(time) {
  let date = new Date(time.seconds * 1000);

  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  let hour = date.getHours();
  let min = date.getMinutes();
  let amPm = hour < 12 ? "A.M." : "P.M.";

  if (hour > 12) {
    hour -= 12;
  }

  month = (month < 10 ? "0" : "") + month;
  day = (day < 10 ? "0" : "") + day;
  hour = (hour < 10 ? "0" : "") + hour;
  min = (min < 10 ? "0" : "") + min;

  let formattedDate =  `${day}/${month}/${year} ${hour}:${min} ${amPm}`;

  return formattedDate;
}

function getQueryVar(name) {
  let query = window.location.search.substring(1);
  let vars = query.split("&");
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split("="); 
    if (pair[0] == name) {
      return pair[1];
    }
  }
  return false;
}

function getPlan(planId) {
  return planes.find(p => p.id === planId);
}

function getCity(id) {
  return cities.find(c => c.id === id);
}

function PurchaseDetailsPage({ showSnackbar, showCustomDialog }) {
  const [result, setResult] = React.useState(null);
  const [city, setCity] = React.useState(null);
  const [plan, setPlan] = React.useState(null);
  const paymentId = getQueryVar("payment_id");
  const status = getQueryVar("status");
  const approved = status === "approved";
  const preferenceId = getQueryVar("preference_id");
  const paymentType = getQueryVar("payment_type");
  const merchantOrderId = getQueryVar("merchant_order_id");
  const collectionId = getQueryVar("collection_id");
  const collectionStatus = getQueryVar("collection_status");
  const xsSpan = 11;
  const smSpan = 10;
  const mdSpan = 6;

  React.useEffect(() => {
    fireDb.collection('pagos').doc(preferenceId).get()
      .then(data => {
        if (!data.exists) {
          window.location.href='/'
          return;
        }
        setResult(data.data());
      })
      .catch(e => {
        window.location.href='/';
      }
    );
  }, []);

  React.useEffect(() => {
    if (result == null) {
      return;
    }

    if (approved) {
      showSnackbar("Transacción exitosa!", "success");
    } else {
      showSnackbar("Transacción rechazada, Favor de Revisar", "error");
    }
    
    setCity(getCity(result.client.city));
    
  }, [result]);

  React.useEffect(() => {
    if (city == null) {
      return;
    }
    setPlan(getPlan(result.planId));
  }, [city]);

  React.useEffect(() => {
    if (plan == null) {
      return;
    }

    fireDb.collection('pagos').doc(preferenceId).set({
      ...result,
      client: {
        ...result.client,
        cityName: city.name
      },
      pago: {
        ...result.pago,
        status: status,
        approved: approved,
        id: paymentId,
        paymentType: paymentType,
        merchantOrderId: merchantOrderId,
        collectionId: collectionId,
        collectionStatus: collectionStatus
      }
    });

    if (!approved) {
      return;
    }

    let htmlCliente = `
    <ul>
      <li>Nombre: ${result.client.name} </li>
      <li>Email: ${result.client.email} </li>
      <li>Telefono: ${result.client.phone} </li>
      <li>Ciudad: ${city.name} </li>
    </ul>
    `;

    let htmlPlan = `
    <ul>
      <li>Plan Adquirido: ${result.pago.concepto}</li>
      <li>Plan Adquirido: ${result.plazo} MESES CON PAGOS QUINCENALES DE ${money.format(result.parcialidad)} (10% DE DESCUENTO AL DOMICILIAR)</li>
      <li>Tipo de pago: ${result.anticipo ? "ANTICIPO" : "PRIMER PAGO"} </li>
      <li>Importe: ${money.format(result.pago.total)} </li>
      <li>No. operación: ${paymentId} </li>
      <li>Empresa: San Martín Grupo Funerario </li>
      <li>Ciudad: ${city.name} </li>
      <li>Fecha: ${getFormattedDate(result.date)} </li>
    </ul>
    `;

    sendCompraResumenGerente(result.client.name, city.id, htmlCliente, htmlPlan);
    sendCompraResumenCliente(result.client.email, result.client.name, htmlPlan);
  }, [plan]);

  const handleButtonClick = e => {
    if (approved) {
      window.location.href='/';
    } else {
      window.location.href='/planes-y-productos';
    }
  }

  return (
    <React.Fragment>
      { plan ? 
        <Box mt={16}>
          <Grid item container
            direction="column"
            justify="center"
            alignItems="flex-start"
          >
            <Grid item container
              direction="column"
              justify="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item container 
                xs={xsSpan} 
                sm={smSpan} 
                md={mdSpan}
                direction="row"
                justify="center"
                spacing={2}
                alignItems="center"
              >
                <Grid item container justify="center" xs>
                <img 
                  src={plan.logoPrimary} 
                  alt="" 
                  width="20%"
                  style={{ 
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto"
                  }}/>
                </Grid>
              </Grid>

              <Grid item container 
                xs={xsSpan} 
                sm={smSpan} 
                md={mdSpan}
                direction="row"
                justify="center"
                spacing={2}
                alignItems="center"
              >
                <Grid item container justify="flex-end" xs>
                  <TextView variant="body2" fontWeight={400}>
                    Plan Adquirido:
                  </TextView>
                </Grid>
                <Grid item container justify="flex-start" xs>
                  <TextView variant="body2" fontWeight={200}>
                    {result.pago.concepto}
                  </TextView>
                </Grid>
              </Grid>

              <Grid item container 
                xs={xsSpan} 
                sm={smSpan} 
                md={mdSpan}
                direction="row"
                justify="center"
                spacing={2}
                alignItems="center"
              >
                <Grid item container justify="flex-end" xs>
                  <TextView variant="body2" fontWeight={400}>
                    Plazo:
                  </TextView>
                </Grid>
                <Grid item container justify="flex-start" xs>
                  <TextView variant="body2" fontWeight={200}>
                    {result.plazo} MESES CON PAGOS QUINCENALES DE {money.format(result.parcialidad)} (10% DE DESCUENTO AL DOMICILIAR)
                  </TextView>
                </Grid>
              </Grid>

              <Grid item container 
                xs={xsSpan} 
                sm={smSpan} 
                md={mdSpan}
                direction="row"
                justify="center"
                spacing={2}
                alignItems="center"
              >
                <Grid item container justify="flex-end" xs>
                  <TextView variant="body2" fontWeight={400}>
                    Tipo de Pago:
                  </TextView>
                </Grid>
                <Grid item container justify="flex-start" xs>
                  <TextView variant="body2" fontWeight={200}>
                    {result.anticipo ? "ANTICIPO" : "PRIMER PAGO"}
                  </TextView>
                </Grid>
              </Grid>

              <Grid item container 
                xs={xsSpan} 
                sm={smSpan} 
                md={mdSpan}
                direction="row"
                justify="center"
                spacing={2}
                alignItems="center"
              >
                <Grid item container justify="flex-end" xs>
                  <TextView variant="body2" fontWeight={400}>
                    Importe:
                  </TextView>
                </Grid>
                <Grid item container justify="flex-start" xs>
                  <TextView variant="body2" fontWeight={200}>
                    {money.format(result.pago.total)}
                  </TextView>
                </Grid>
              </Grid>

              <Grid item container 
                xs={xsSpan} 
                sm={smSpan} 
                md={mdSpan}
                direction="row"
                justify="center"
                spacing={2}
                alignItems="center"
              >
                <Grid item container justify="flex-end" xs>
                  <TextView variant="body2" fontWeight={400}>
                    Estatus:
                  </TextView>
                </Grid>
                <Grid item container justify="flex-start" xs>
                  <TextView variant="body2" fontWeight={200}>
                    {approved ? "Aprobado" : "Rechazado"}
                  </TextView>
                </Grid>
              </Grid>

              <Grid item container 
                xs={xsSpan} 
                sm={smSpan} 
                md={mdSpan}
                direction="row"
                justify="center"
                spacing={2}
                alignItems="center"
              >
                <Grid item container justify="flex-end" xs>
                  <TextView variant="body2" fontWeight={400}>
                    No. operación:
                  </TextView>
                </Grid>
                <Grid item container justify="flex-start" xs>
                  <TextView variant="body2" fontWeight={200}>
                    {paymentId}
                  </TextView>
                </Grid>
              </Grid>

              <Grid item container 
                xs={xsSpan} 
                sm={smSpan} 
                md={mdSpan}
                direction="row"
                justify="center"
                spacing={2}
                alignItems="center"
              >
                <Grid item container justify="flex-end" xs>
                  <TextView variant="body2" fontWeight={400}>
                    Empresa:
                  </TextView>
                </Grid>
                <Grid item container justify="flex-start" xs>
                  <TextView variant="body2" fontWeight={200}>
                    San Martín Grupo Funerario
                  </TextView>
                </Grid>
              </Grid>

              <Grid item container 
                xs={xsSpan} 
                sm={smSpan} 
                md={mdSpan}
                direction="row"
                justify="center"
                spacing={2}
                alignItems="center"
              >
                <Grid item container justify="flex-end" xs>
                  <TextView variant="body2" fontWeight={400}>
                    Ciudad:
                  </TextView>
                </Grid>
                <Grid item container justify="flex-start" xs>
                  <TextView variant="body2" fontWeight={200}>
                    {city.name}
                  </TextView>
                </Grid>
              </Grid>

              <Grid item container 
                xs={xsSpan} 
                sm={smSpan} 
                md={mdSpan}
                direction="row"
                justify="center"
                spacing={2}
                alignItems="center"
              >
                <Grid item container justify="flex-end" xs>
                  <TextView variant="body2" fontWeight={400}>
                    Fecha y hora:
                  </TextView>
                </Grid>
                <Grid item container justify="flex-start" xs>
                  <TextView variant="body2" fontWeight={200}>
                    {getFormattedDate(result.date)}
                  </TextView>
                </Grid>
              </Grid>

              <Grid item container 
                xs={xsSpan} 
                sm={smSpan} 
                md={mdSpan}
                direction="row"
                justify="center"
                spacing={2}
                alignItems="center"
              >
                <Grid item container justify="center" xs>
                  <TextView variant="body2" fontWeight={500} textAlign="center">
                    El equipo de ventas se pondrá en contacto lo más pronto posible para continuar con el proceso de firma del contrato de venta.
                  </TextView>
                </Grid>
              </Grid>

              <Grid item container
                direction="row"
                justify="center"
                spacing={2}
              >
                <Grid item>
                  <Box mt={4}>
                    <Button 
                      variant="contained" 
                      color="primary" 
                      onClick={handleButtonClick}
                    >
                      {approved ? "Aceptar" : "Regresar"}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      :
        <Grid item container
          direction="column"
          justify="center"
          alignItems="center"
        >
          <Grid item>
            <TextView variant="h6" m={16}>
              Procesando transacción...
            </TextView>
          </Grid>
        </Grid>
      }      
    </React.Fragment>
  );
}

export default PurchaseDetailsPage;