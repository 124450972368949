import React from 'react';
import ScriptTag from 'react-script-tag';

import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';

// Components
import TextView from '../../../components/TextView';

const money = new Intl.NumberFormat('es-MX', {
  style: 'currency',
  currency: 'MXN',
});

function createData(position, noPay, importe) {
  return { position, noPay, importe };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  return array;
  /*const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);*/
}

const headCells = [
  { id: 'noPay', numeric: false, disablePadding: true, label: 'Año' },
  { id: 'importe', numeric: true, disablePadding: false, label: 'Importe' },
];

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <Hidden xsDown>
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'Seleccionar todos los pagos' }}
            />
          </TableCell>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </Hidden>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { user, rowsSelected, numSelected, amountToPay, } = props;
  const { getLinkDePago, setLinkDePago } = props;

  const handlePayAction = () => {
    if (amountToPay != 0) {
      getLinkDePago(user, rowsSelected, setLinkDePago, amountToPay);
    }
  }

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <Hidden xsDown>
        {numSelected > 0 ? (
          <Typography align='left' className={classes.title} color="inherit" variant="subtitle1" component="div">
            {numSelected} seleccionados
          </Typography>
        ) : (
          <Typography align='left' className={classes.title} variant="h6" id="tableTitle" component="div">
            Seleccionar pagos
          </Typography>
        )}
      </Hidden>


      {numSelected > 0 ?
        <Button variant="contained" color="primary" size="small" onClick={handlePayAction}>
          Pagar {money.format(amountToPay)}
        </Button>
        :
        <Button variant="outlined" color="primary" size="small" onClick={handlePayAction}>
          {money.format(amountToPay)}
        </Button>
      }
    </Toolbar>
  );
};


EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export default function EnhancedTable2({ user, getLinkDePago, setLinkDePago }) {
  //let rows = user.pagosTodos.map((pago, index) => {


  //const minToPay = user.listaAniosDebe[0].importe;
  const minToPay = 0;
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('dateMillis');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [amountToPay, setAmountToPay] = React.useState(minToPay);
  const [rows, setRows] = React.useState(user.listaAniosDebe.map((pago, index) => {
    return createData(
      index,
      `${pago.anio}`,
      pago.importe);
  }));
  const [rowsBefore, setRowsBefore] = React.useState(user.listaAniosDebe.map((pago, index) => {
    return createData(
      index,
      `${pago.anio}`,
      pago.importe,);
  }));

  React.useEffect(() => {
    let totalAmount = 0;
    if (selected.length > 0) {
      const lastSelected = rows.find(r => r.noPay === selected[selected.length - 1]);
      rows.forEach(r => totalAmount += r.position <= lastSelected.position ? r.importe : 0);
    } else {
      totalAmount = minToPay;
    }
    setAmountToPay(totalAmount);
  }, [selected]);

  const handleRequestSort = (event, property) => {
    /*const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);*/
  };

  const handleSelectAllClick = (event) => {

    if (event.target.checked) {
      const newSelecteds = rows.filter(r => r.importe != 0).map((n) => n.noPay);

      setSelected(newSelecteds);
    } else {
      setSelected([]);
    }
  };

  const handleClick = (event, name) => {
    const selectedRow = rows.find(r => r.noPay === name);
    if (selectedRow.importe > 0) {
      const filterRows = rows.filter(r => r.position < selectedRow.position && r.importe != 0);

      const selectedIndex = selected.indexOf(name);
      let newSelected = [];

      if (selectedIndex === -1) {
        const previousSelecteds = filterRows.map(r => r.noPay);

        newSelected = newSelected.concat(previousSelecteds, name);
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex)
        );
      }
      setSelected(newSelected);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <EnhancedTableToolbar
        user={user}
        rowsSelected={selected}
        getLinkDePago={getLinkDePago}
        setLinkDePago={setLinkDePago}
        numSelected={selected.length}
        amountToPay={amountToPay}
        minToPay={minToPay} />
      <TableContainer>
        <Table>
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(row.noPay);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.noPay)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.index}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} />
                    </TableCell>
                    <TableCell component="th" id={labelId} scope="row" padding="none">
                      {row.noPay}
                    </TableCell>
                    <TableCell align="right">
                      {money.format(row.importe)}
                    </TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Box mt={2} mx={2}>
        <Grid item container direction="row" justify="flex-start" alignItems="flex-start">
          <PaginationEstadoCuenta
            rows={rows}
            rowsPerPage={rowsPerPage}
            page={page}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Grid>
      </Box>
    </div>
  );
}


function PaginationEstadoCuenta({ rows, rowsPerPage, page, handleChangePage, handleChangeRowsPerPage, handleEstadoDeCuenta }) {
  return (
    <React.Fragment>
      <Hidden xsDown>
        <Grid item container direction="row" justify="flex-start" alignItems="flex-start">
          <Grid item>
          </Grid>
          <Grid item container xs direction="row" justify="flex-end" alignItems="center">
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Filas por página"
              labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count !== -1 ? count : `more than ${to}`}`} />
          </Grid>
        </Grid>
      </Hidden>
      <Hidden smUp>
        <Grid item container direction="column" justify="center" alignItems="center">
          <Grid item>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              labelRowsPerPage="Filas por página"
              labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count !== -1 ? count : `more than ${to}`}`} />
          </Grid>
        </Grid>
      </Hidden>
    </React.Fragment>
  );
}