import React from 'react';

// @material-ui/core components
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  IconButton,
  Menu,
  MenuItem, Typography
} from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import MoreVertIcon from '@material-ui/icons/MoreVert';

// Components
import HeaderBar from '../../components/HeaderBar';
import HeaderLinks from '../../components/PaymentHeaderLinks';
import TextView from '../../components/TextView';
import { useWindowDimensions } from '../../App';

import {
  contractList, estadoDeCuenta
} from "../../Api";

const themeTable = createMuiTheme({
  palette: {
    primary: {
      main: '#35647D'
    },
    secondary: {
      main: '#326295',
    }
  }
});
const money = new Intl.NumberFormat('es-MX', {
  style: 'currency',
  currency: 'MXN',
});

function ContractListPage() {
  const { width } = useWindowDimensions();
  const cardMargin = width * 0.005;

  const userSession = JSON.parse(localStorage.getItem('user'));
  const [user, setUser] = React.useState(userSession ? userSession : {});
  const [anchorEls, setAnchorEls] = React.useState({});
  const [contratos, setContratos] = React.useState([]);


  const handleClick = (event, contratoId) => {
    setAnchorEls({ ...anchorEls, [contratoId]: event.currentTarget });
  };

  const handleClose = (contratoId) => {
    setAnchorEls({ ...anchorEls, [contratoId]: null });
  };
  const obtenerEstadoDeCuenta = async (contrato) => {
    let response = await estadoDeCuenta(contrato.flContrato, user.idSistema);
    let url = response.data;
    window.open(process.env.REACT_APP_SIC_API + url, '_blank');
  };
  const pagarParcialidades = (contrato) => {
    let info = user;
    info.folioContrato = contrato.flContrato;
    localStorage.setItem('user', JSON.stringify(info));
    window.location.href = '/payment/details';
  };
  const pagarCuotas = (contrato) => {
    let info = user;
    info.folioContrato = contrato.flContrato;
    localStorage.setItem('user', JSON.stringify(info));
    window.location.href = '/payment/details/mtto';
  };

  React.useEffect(async () => {
    if (!user || user.folioContrato == null || user.folioContrato === '' || user.folioContrato === '0' || !user.sesion) {
      closeSession();
    } else {
      const response = await contractList(user.idSistema, user.idCliente)
      if (response.isValid) {
        setContratos(response.data)
      }
    }
  }, []);

  return (
    <Box align={'center'}>
      <React.Fragment>
        <Box style={{ width: '100%' }}>
          <PaymentAppBar username={user.nb_Cliente} />
          <Box mt={14}>
            <Grid item container direction="column" justify="center" alignItems="center">
              <Grid item container xs={12}>
                <Box width={1} mx={cardMargin} mb={cardMargin} mt={cardMargin}>
                  <ThemeProvider theme={themeTable}>
                    <TextView variant="h6" align={'center'} fontWeight={400} ><b>CONTRATOS</b></TextView><br />
                    <Card elevation={2} style={{ backgroundColor: "#ffffffcc", borderRadius: 12 }}>
                      <CardContent style={{ padding: 0 }}>
                        <TableContainer>
                          <Hidden xsDown>
                            <TableContainer>
                              <Table>
                                <TableHead>
                                  <TableRow style={{ backgroundColor: '#35647d' }}>
                                    <TableCell><Typography variant="h6" style={{ color: 'white' }} align="center"><b>Folio</b></Typography></TableCell>
                                    <TableCell><Typography variant="h6" style={{ color: 'white' }} align="center"><b>Plan</b></Typography></TableCell>
                                    <TableCell><Typography variant="h6" style={{ color: 'white' }} align="center"><b>Saldo</b></Typography></TableCell>
                                    <TableCell><Typography variant="h6" style={{ color: 'white' }} align="center"><b>Saldo vencido</b></Typography></TableCell>
                                    <TableCell><Typography variant="h6" style={{ color: 'white' }} align="center"><b>Estatus</b></Typography></TableCell>
                                    <TableCell><Typography variant="h6" style={{ color: 'white' }} align="center"><b>Estatus mantenimiento</b></Typography></TableCell>
                                    <TableCell><Typography variant="h6" style={{ color: 'white' }} align="center"><b>Acciones</b></Typography></TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {contratos.map((contrato) => (
                                    <TableRow key={contrato.flContrato}>
                                      <TableCell><Typography variant="body1" align="center">{contrato.flContrato}</Typography></TableCell>
                                      <TableCell><Typography variant="body1" align="center">{contrato.descripcion}</Typography></TableCell>
                                      <TableCell><Typography variant="body1" align="center">{money.format(contrato.saldo)}</Typography></TableCell>
                                      <TableCell><Typography variant="body1" align="center" style={{ color: contrato.vencido > 0 ? 'red' : 'inherit' }}>
                                        {contrato.vencido ? <b>{money.format(contrato.vencido)}</b> : money.format(contrato.vencido)}
                                      </Typography></TableCell>
                                      <TableCell><Typography variant="body1" align="center">{contrato.deEstatus}</Typography></TableCell>
                                      <TableCell>
                                        <Typography variant="body1" align="center" style={{ color: contrato.debeMtto && contrato.aplicaMtto ? 'red' : 'inherit' }}>
                                          {contrato.debeMtto && contrato.aplicaMtto ? <b>VENCIDO</b> : !contrato.debeMtto && contrato.aplicaMtto ? 'AL DÍA' : 'N/A'}
                                        </Typography>
                                      </TableCell>
                                      <TableCell align="center">
                                        <IconButton aria-controls={`simple-menu-${contrato.flContrato}`} aria-haspopup="true" onClick={(event) => handleClick(event, contrato.flContrato)}>
                                          <MoreVertIcon />
                                        </IconButton>
                                        <Menu
                                          id={`simple-menu-${contrato.flContrato}`}
                                          anchorEl={anchorEls[contrato.flContrato]}
                                          keepMounted
                                          open={Boolean(anchorEls[contrato.flContrato])}
                                          onClose={() => handleClose(contrato.flContrato)}
                                        >
                                          <MenuItem onClick={() => obtenerEstadoDeCuenta(contrato)}>Estado de cuenta</MenuItem>
                                          {contrato.aplicaMtto && (
                                            <MenuItem onClick={() => pagarCuotas(contrato)}>Pagar mantenimiento</MenuItem>
                                          )}
                                          {contrato.deEstatus === 'AUTORIZADO' && (
                                            <MenuItem onClick={() => pagarParcialidades(contrato)}>Pagar mensualidad</MenuItem>
                                          )}
                                        </Menu>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Hidden>
                          <Hidden smUp>
                            {contratos.map((contrato) => (
                              <Card key={contrato.flContrato} elevation={2} style={{ backgroundColor: "#ffffffcc", borderRadius: 12, marginBottom: 16 }}>
                                <CardContent>
                                  <Grid container spacing={2}>
                                    <Grid item xs={10}>
                                      <Typography variant="h6" align="center"><b>{contrato.descripcion}</b></Typography>
                                    </Grid>
                                    <Grid item xs={2} align="center">
                                      <IconButton aria-controls={`simple-menu-${contrato.flContrato}`} aria-haspopup="true" onClick={(event) => handleClick(event, contrato.flContrato)}>
                                        <MoreVertIcon />
                                      </IconButton>
                                      <Menu
                                        id={`simple-menu-${contrato.flContrato}`}
                                        anchorEl={anchorEls[contrato.flContrato]}
                                        keepMounted
                                        open={Boolean(anchorEls[contrato.flContrato])}
                                        onClose={() => handleClose(contrato.flContrato)}
                                      >
                                        <MenuItem onClick={() => obtenerEstadoDeCuenta(contrato)}>Estado de cuenta</MenuItem>
                                        {contrato.aplicaMtto && (
                                          <MenuItem onClick={() => pagarCuotas(contrato)}>Pagar mantenimiento</MenuItem>
                                        )}
                                        {contrato.deEstatus === 'AUTORIZADO' && (
                                          <MenuItem onClick={() => pagarParcialidades(contrato)}>Pagar mensualidad</MenuItem>
                                        )}
                                      </Menu>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Typography variant="body1"><b>Folio:</b> {contrato.flContrato}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Typography variant="body1" align="center"><b>Saldo:</b> {money.format(contrato.saldo)}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Typography variant="body1"><b>Saldo vencido:</b></Typography>
                                      <Typography variant="body1" align="center" style={{ color: contrato.vencido > 0 ? 'red' : 'inherit' }}>
                                        {contrato.vencido ? <b>{money.format(contrato.vencido)}</b> : money.format(contrato.vencido)}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Typography variant="body1" align="center"><b>Estatus de plan:</b> {contrato.deEstatus}</Typography>
                                    </Grid>
                                    {contrato.aplicaMtto && (
                                      <Grid item xs={6}>
                                        <Typography variant="body1"><b>Mantenimiento:</b></Typography>
                                        <Typography variant="body1" align="center" style={{ color: contrato.debeMtto && contrato.aplicaMtto ? 'red' : 'inherit' }}>
                                          {contrato.debeMtto && contrato.aplicaMtto ? <b>VENCIDO</b> : !contrato.debeMtto && contrato.aplicaMtto ? 'AL DÍA' : 'N/A'}
                                        </Typography>
                                      </Grid>
                                    )}

                                  </Grid>
                                </CardContent>
                              </Card>
                            ))}
                          </Hidden>
                        </TableContainer>
                      </CardContent>
                    </Card>
                  </ThemeProvider>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </React.Fragment>
    </Box>
  );
}

function closeSession() {
  localStorage.clear();
  window.location.href = '/payment'
}

function PaymentAppBar({ username = "¿?" }) {
  return (<HeaderBar logo="/image/SMDark.png" rightLinks={<HeaderLinks closeSession={closeSession} />} fixed color="secondary" />);
}

export default ContractListPage;
