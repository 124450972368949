import React from 'react';

// Firebase
import { fireDb } from '../../../Firebase';

// @material-ui/core components
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

//Sections
import CuentasSM from './CuentasSM';
// Components
import TextView from '../../../components/TextView';

function MethodResume({ name, icon, width, height, showDialog}) {
  let scale = width / height;
  let showWarning = name === "Casa Ley" || name === "Tiendas Oxxo";
  let showButton = name === "Santander";
  let marginName = !showWarning ? 4 : showButton ? 4 : 1;
  
  height = 72;
  width = height * scale;

  if (width > 250) {
    scale = 256 / width;
    width *= scale;
    height *= scale;
  }

  return (
    <Grid item xs={12} sm={6} md={4} style={{ width: 256 }}>
      <Grid container
        direction="column"
        justify="flex-start"
        alignItems="center"
      >
        <Grid item xs>
          <Box>
            <img src={icon} width={width} height={height} alt="" />
          </Box>
        </Grid>
        <TextView variant="subtitle1" align="center" mt={2} mb={marginName}>
          {name}
        </TextView>
        {showWarning ? (
          <TextView variant="body2" align="center" color="primary" fontWeight={500} mb={4}>
            Aplica en todo Sinaloa, excepto Mazatlán.
          </TextView>
        ) : showButton ? (
          <Button 
            variant="outlined" 
            color="Danger"
            onClick={() => {
              showDialog(
                <CuentasSM/>,
                "Cuentas de San Martín",
                "Aceptar");
            }}
          >
            Ver cuentas
          </Button>
        ) : 
          null
        }
      </Grid>
    </Grid>
  );
}

function PayMethodsSection( {showDialog}) {
  const [ methods, setMethods ] = React.useState([]);

  React.useEffect(() => {
    fireDb.collection('paymentMethods').orderBy('order').get()
    .then(snapshot => {
      setMethods(snapshot.docs.map(doc => doc.data()));
    });
  }, []);

  return (
    <Box mb={6}>
      <Grid container>

        <Grid item container xs={12}
          direction="column"
          justify="center"
        >
          <TextView variant="h4" align="center" mb={2} textColor="#326295" fontWeight={500}>
            Formas de pago
          </TextView>
          <TextView align="center" mb={6} mx={2}>
            Para su comodidad, puede efectuar los pagos de sus parcialidades en:
          </TextView>
        </Grid>

        <Grid item container xs
          direction="column"
          justify="center"
          alignItems="center"
        >
          <Grid item container xs={11} sm={10} md={9} lg={8}
            direction="row"
            justify="center"
            spacing={4}
          >
            {
              methods.map((payMethod, key) => (
                <MethodResume 
                  key={key}
                  name={payMethod.name} 
                  icon={payMethod.icon}
                  width={payMethod.width}
                  height={payMethod.height}
                  showDialog={showDialog}
                  />
              ))
            }
          </Grid>
        </Grid>

      </Grid>
    </Box>
  );
}

export default PayMethodsSection;
