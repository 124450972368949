import React from 'react';

// @material-ui/core components
import { createMuiTheme, makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

// @material-ui/icons
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import YoutubeIcon from '@material-ui/icons/YouTube';
import PhoneIcon from '@material-ui/icons/Phone';
import AvisoPrivacidad from '../pages/payment/sections/AvisoPrivacidad';

// Components
import TextView from '../components/TextView';

const theme = createMuiTheme({ spacing: 4 });

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    backgroundColor: "#e5e5e5",
    borderRadius: theme.spacing(4, 4, 0, 0)
  }
});

function FooterSmall({ showDialog }) {
  const classes = useStyles();
  showDialog = showDialog ? showDialog : () => { };
  return (
    <Grid item container>
      <Card
        className={classes.root}
      >
        <CardContent>

          <Grid container
            direction="row"
            alignItems="flex-start"
            spacing={2}
          >

            <Grid item xs={6} sm>
              <Grid item>
                <Grid container
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                >
                  <Grid item>
                    <TextView variant="h6">
                      Montebello
                    </TextView>
                  </Grid>
                </Grid>
                <Grid item container justify="flex-start">
                  <IconButton href="tel:6677128000">
                    <PhoneIcon />
                  </IconButton>
                </Grid>
                <TextView>
                  <Link
                    color="inherit"
                    target="Maps"
                    href="https://goo.gl/maps/5WSVN6esHCJdiXrY6"
                  >
                    Calle Montebello 22 Oriente, Col. Montebello, 80227 Culiacán Rosales, Sin.
                  </Link>
                </TextView>
              </Grid>
            </Grid>

            <Grid item xs={6} sm>
              <Grid container
                direction="row"
                justify="flex-end"
                alignItems="center"
              >
                <Grid item>
                  <TextView variant="h6">
                    Zapata
                  </TextView>
                </Grid>
              </Grid>
              <Grid item container justify="flex-end">
                <IconButton href="tel:6677128000">
                  <PhoneIcon />
                </IconButton>
              </Grid>
              <TextView align="right">
                <Link
                  color="inherit"
                  target="Maps"
                  href="https://goo.gl/maps/jzdKHSAahkcpvhHQA"
                >
                  Blvd. Emiliano Zapata 145, Guadalupe, Jorge Almada, 80220 Culiacán Rosales, Sin.
                </Link>
              </TextView>
            </Grid>
          </Grid>

          <Grid item container xs
            direction="row"
            justify="center"
            alignItems="center"
            spacing={1}
            style={{
              marginTop: theme.spacing(4),
              marginBottom: theme.spacing(4)
            }}
          >
            <Grid item>
              <IconButton
                href="https://www.facebook.com/sanmartinGF"
                target="Facebook"
              >
                <FacebookIcon fontSize="large" />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                href="https://www.instagram.com/sanmartingf"
                target="Instagram"
              >
                <InstagramIcon fontSize="large" />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                href="https://www.youtube.com/channel/UC94sz-VOwXpSOsrKIjGzdcw"
                target="Youtube"
              >
                <YoutubeIcon fontSize="large" />
              </IconButton>
            </Grid>
          </Grid>

          <Grid item container justify="center" xs
            style={{
              marginBottom: theme.spacing(4)
            }}
          >
            <Typography variant="caption" align="center">
              <Link
                color="inherit"
                target="Maps"
                href="tel:6673369128"
              >
                Los Mochis Teléfono: (667) 336 9128
              </Link>
              <br />
              <Link
                color="inherit"
                target="Maps"
                href="https://goo.gl/maps/q8X5m9aXUC3ry2QCA"
              >
                Ote. Blvd. Rosendo G. Castro Pte. 495, Centro, 81200
              </Link>
            </Typography>
          </Grid>

          <Grid item xs container direction="column" justify="center" alignItems="center">
            <Typography variant="caption" align="center">
              <Link
                color="inherit"
                target="Maps"
                href="tel:6677165939"
              >
                OFICINAS CORPORATIVAS Teléfono: (667) 716 59 39
              </Link>
              <br />
              <Link
                color="inherit"
                target="Maps"
                href="https://goo.gl/maps/kfXZR6APSmfwCfpT6"
              >
                Domingo Rubí 836 Sur. Col. Guadalupe, Culiacán, Sinaloa, México. C.P. 80220
              </Link>
            </Typography>
          </Grid>

          <Grid item container xs
            justify="center"
          >
            <TextView align="center" mt={2}>
              <Link onClick={() => {
                showDialog(
                  <AvisoPrivacidad />,
                  <b>AVISO DE PRIVACIDAD GRUPO SAN MARTÍN</b>,
                  "Aceptar");
              }}>Aviso de privacidad
              </Link>
              <br />
              &copy; 2025, hecho por <Link color="inherit" target="Ryactiv" href="https://www.sanmartin.com.mx">Grupo San Martin</Link>
            </TextView>
          </Grid>

        </CardContent>
      </Card>
    </Grid>
  );
}

export default FooterSmall;
