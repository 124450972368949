import React from 'react';

import html2canvas from 'html2canvas';
import ScriptTag from 'react-script-tag';

// nodejs library to set properties for components
import PropTypes from "prop-types";

// @material-ui/core components
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Hidden from '@material-ui/core/Hidden';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import SwipeableViews from 'react-swipeable-views';
import Divider from '@material-ui/core/Divider';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from "@material-ui/core/IconButton";
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Link from '@material-ui/core/Link';

// @material-ui/icons
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import YoutubeIcon from '@material-ui/icons/YouTube';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIosSharp';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIosSharp';

import GalleryCarousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';

import { ScrollMenu } from "react-horizontal-scrolling-menu";

import BuyerFormModal from './BuyerFormModal';
import ContactFormModal from './ContactFormModal';

import { sendContactUsEmail } from '../../../Api';


// Components
import TextView from '../../../components/TextView';
import { getScreenRangeInt, getLocalDateString, XS, SM, MD, LG } from '../../../App';
import { planes } from '../../../ArraysDB';
import firebase, { fireDb } from '../../../Firebase';

import ScrollAnimation from 'react-animate-on-scroll';

import { eventTrack } from '../../../Analytics';

const NUMBER_PATTERN = /^[0-9]+$/;
const HORIZONTAL_VIDEOS = [
  "/video/plan_tranquilidad/horizontal_carrusel_carro.mp4",
  "/video/plan_tranquilidad/horizontal_carrusel_carrera.mp4",
  "/video/plan_tranquilidad/horizontal_carrusel_casa.mp4",
  "/video/plan_tranquilidad/horizontal_carrusel_negocio.mp4",
  "/video/plan_tranquilidad/horizontal_carrusel_vacaciones.mp4"
];
const VERTICAL_VIDEOS = [
  "/video/plan_tranquilidad/vertical_carrusel_carro.mp4",
  "/video/plan_tranquilidad/vertical_carrusel_carrera.mp4",
  "/video/plan_tranquilidad/vertical_carrusel_casa.mp4",
  "/video/plan_tranquilidad/vertical_carrusel_negocio.mp4",
  "/video/plan_tranquilidad/vertical_carrusel_vacaciones.mp4"
];

const money = new Intl.NumberFormat('es-MX', {
  style: 'currency',
  currency: 'MXN',
});

const themePrimary = createMuiTheme({
  palette: {
    primary: {
      main: '#326295'
    },
    secondary: {
      main: '#326295',
    }
  }
});

function getPlan() {
  return planes[4];
}

function Banner() {
  const [range, setRange] = React.useState(getScreenRangeInt());
  const [videos, setVideos] = React.useState(
    getScreenRangeInt() >= SM ? HORIZONTAL_VIDEOS : VERTICAL_VIDEOS
  );
  const [styles, setStyles] = React.useState({
    buttonSize: "small",
    verMasMarginTop: -6,
    verMasMarginLeft: 16,
    titleVariant: "h6",
    titleMarginTop: 8,
    videoMarginTop: 6,
    solicitarRightPos: 50,
    titleMarginX: 0
  });
  
  React.useEffect(() => {
    if (range === XS) {
      setStyles({
        ...styles,
        videoMarginTop: 3,
        verMasMarginTop: 0,
        verMasMarginLeft: 0,
        titleMarginTop: 2,
        solicitarRightPos: 10,
        titleVariant: "h6",
        titleMarginX: 2
      });
    }
    if (range === SM) {
      setStyles({
        ...styles,
        verMasMarginTop: -4,
        verMasMarginLeft: 12,
        titleMarginTop: 6,
      });
    }
    if (range > MD) {
      setStyles({
        ...styles,
        buttonSize: "large",
        verMasMarginTop: -8,
        verMasMarginLeft: 22,
        titleVariant: "h4"
      });
    }
  }, [range]);

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="flex-start"
      style={{
        width: "100%",
        height: "100vh",
        background: "#f0f0f0"
      }}
    >
      <Box mt={styles.videoMarginTop}>
        <Grid item>
          <GalleryCarousel 
            isAutoPlaying={true}
            autoPlayInterval={6000}
            hasMediaButton={false}
            hasSizeButton={false}
            hasIndexBoard={false}
            hasDotButtons="bottom"
            hasThumbnails={false}
            leftIcon={(<ArrowBackIcon/>)}
            rightIcon={(<ArrowForwardIcon/>)}
            children={
              videos.map(v => (
                <Card
                  elevation={0}
                  style={{
                    width: "100%",
                    height: "auto"
                  }}
                >
                  <video 
                    width="100%" 
                    height="100%"
                    muted
                    autoPlay 
                    loop 
                    playsInline
                  >
                    <source src={v} type="video/mp4"/>
                  </video>
                </Card>
              ))
            }/>
        </Grid>
      </Box>

      <Hidden xsDown>
        <Box ml={styles.verMasMarginLeft} mt={styles.verMasMarginTop}>
          <Button 
            variant="contained" 
            size={styles.buttonSize}
            style={{
              background: '#326295',
              color: "#ffffff"
            }}
            className="animation-pulse"
            onClick={(e) => {
              e.preventDefault();
              window.location.href='#planDescripcion';
            }}
          >
            VER MÁS
          </Button>
        </Box>
      </Hidden>

      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
      >
        <TextView
          variant={styles.titleVariant}
          textColor="#326295"
          textAlign="center"
          mt={styles.titleMarginTop}
          mx={styles.titleMarginX}
        >
          ASEGURA LA TRANQUILIDAD DE TU FAMILIA
        </TextView>
      </Grid>

      <Hidden smUp>
        <Box mt={2} width={1}>
          <Grid xs={12}
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Button 
              variant="contained" 
              size={styles.buttonSize}
              style={{
                background: '#326295',
                color: "#ffffff"
              }}
              onClick={(e) => {
                e.preventDefault();
                window.location.href='#planDescripcion';
              }}
            >
              VER MÁS
            </Button>
          </Grid>
        </Box>
      </Hidden>

      <Box 
        id="planDescripcion"
        style={{
          position: "absolute",
          bottom: 0,
          right: styles.solicitarRightPos
        }}
      >
        
      </Box>

    </Grid>
  );
}

function PagosFlexibles({ selectedPlan, selectedButton, onClickMeses }) {
  return (
    <React.Fragment>
      <Hidden xsDown>
        <ScrollAnimation animateIn='bounceInRight' animateOut='bounceOutLeft'>
          <Grid 
            container 
            direction="row"
            justify="center"
            alignItems="center"
            spacing={2}
          >
            {selectedPlan.pagosConAnticipo.map((pago, index) => (
              <Grid key={index} item>
                <Button 
                  variant={selectedButton === `${pago.plazo} MESES` ? "contained" : "outlined"}
                  size="small"
                  color="primary"
                  className="animation-pulse"
                  onClick={onClickMeses}
                  style={{
                    borderRadius: 16
                  }}
                >
                  {`${pago.plazo} MESES`}
                </Button>
              </Grid>
            ))}
          </Grid>
        </ScrollAnimation>
      </Hidden>
      <Hidden smUp>
        <ScrollAnimation animateIn='bounceInRight' animateOut='bounceOutLeft'>
          <ScrollMenu>
            {selectedPlan.pagosConAnticipo.map((pago, index) => (
              <Box width={90} ml={2}>
                <Button 
                  variant={selectedButton === `${pago.plazo} MESES` ? "contained" : "outlined"}
                  size="small"
                  color="primary"
                  onClick={onClickMeses}
                  style={{
                    borderRadius: 16
                  }}
                >
                  {`${pago.plazo} MESES`}
                </Button>
              </Box>
            ))}
          </ScrollMenu>
        </ScrollAnimation>
      </Hidden>
    </React.Fragment>
  );
}

function Footer() {
  return (
    <Box my={4} mx={8}>
      <Hidden xsDown>
        <Grid
          container 
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item sm={4}>
            <img src="/image/SMBlue.png" width="auto" height={38} alt="" />
          </Grid>
          <Grid item container sm={4}
            direction="row"
            justify="flex-end"
            alignItems="center"
          >
            <Grid item>
              <IconButton 
                href="https://www.facebook.com/sanmartinGF"
                target="Facebook"
                style={{ color: "#326295" }}
              >
                <FacebookIcon fontSize="large" />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton 
                href="https://www.instagram.com/sanmartingf" 
                target="Instagram"
                style={{ color: "#326295" }}
              >
                <InstagramIcon fontSize="large" />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton 
                href="https://www.youtube.com/channel/UC94sz-VOwXpSOsrKIjGzdcw" 
                target="Youtube"
                style={{ color: "#326295" }}
              >
                <YoutubeIcon fontSize="large" />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>
      <Hidden smUp>
        <Grid
          container 
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid item container xs={12}
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Grid item>
              <img src="/image/SMBlue.png" width="auto" height={38} alt="" />
            </Grid>
          </Grid>
          <Grid item container xs={12}
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Grid item>
              <IconButton 
                href="https://www.facebook.com/sanmartinGF"
                target="Facebook"
                style={{ color: "#326295" }}
              >
                <FacebookIcon fontSize="large" />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton 
                href="https://www.instagram.com/sanmartingf" 
                target="Instagram"
                style={{ color: "#326295" }}
              >
                <InstagramIcon fontSize="large" />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton 
                href="https://www.youtube.com/channel/UC94sz-VOwXpSOsrKIjGzdcw" 
                target="Youtube"
                style={{ color: "#326295" }}
              >
                <YoutubeIcon fontSize="large" />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>

      <Box mt={2}>
        <Grid
          container 
          direction="row"
          justify="center"
          alignItems="center"
        >
          <TextView variant="body1" textColor="#326295" align="center">
            VISITA
          </TextView>
          <Button variant="text" size="large" color="primary">
            <Typography 
              variant="body1" 
              style={{
                color: "#326295",
                fontWeight: 700
              }}
              onClick={e => window.location.href='https://sanmartin.com.mx'}
            >
              SANMARTIN.COM.MX
            </Typography>
          </Button>
        </Grid>
      </Box>
    </Box>
  );
}

async function goToCheckout(tipoDePlan, buyerEmail, emailCheck) {
  localStorage.setItem('tipoDePlan', JSON.stringify(tipoDePlan));
  
  if (emailCheck) {
    let emailsRef = fireDb.collection('listaEmailsPromos').doc(getLocalDateString());
    let resEmail = await emailsRef.set({
      emails: firebase.firestore.FieldValue.arrayUnion(buyerEmail)
    }, { merge: true });
  }

  window.location.href='/comprar-plan';
}

function PlanParaisoPage({ showSnackbar, showCustomDialog }) {
  const [range, setRange] = React.useState(getScreenRangeInt());
  const [selectedPlan, setSelectedPlan] = React.useState(null);
  const [selectedPlazo, setSelectedPlazo] = React.useState("");
  // const [selectedButton, setSelectedButton] = React.useState("");
  const [pagoSinAnticipo, setPagoSinAnticipo] = React.useState({
    plazo: 0,
    mensual: {
      primerPago: 0,
      pago: 0,
      total: 0
    },
    quincenal: {
      primerPago: 0,
      pago: 0,
      total: 0
    }
  });
  const [pagoConAnticipo, setPagoConAnticipo] = React.useState({
    plazo: 0,
    mensual: {
      primerPago: 0,
      pago: 0,
      total: 0
    },
    quincenal: {
      primerPago: 0,
      pago: 0,
      total: 0
    }
  });
  const [styles, setStyles] = React.useState({
    titleVariant: "h2",
    bigTitleVariant: "h3",
    descriptionVariant: "h6",
  });

  React.useEffect(() => {
    setSelectedPlan(getPlan());
  }, []);

  React.useEffect(() => {
    if (!sessionStorage.getItem('modalContactHidden')) {
      openContactFormModal();
    } else  {
      if (!sessionStorage.getItem('ifSendMail')) {
        let contact = JSON.parse(localStorage.getItem('contactData'));
        if (contact) {          
          sendContactUsEmail(
            contact.email, 
            contact.name, 
            contact.phone, 
            contact.subject, 
            contact.message
          );
          sessionStorage.setItem('ifSendMail', 'true');
          eventTrack('Modal Contáctanos', 'Enviar', 'Button');
        }
      }
    }
    /*sessionStorage.removeItem("modalContactHidden");
    sessionStorage.removeItem("ifSendMail");
    localStorage.removeItem('contactData');*/
  }, []);

  React.useEffect(() => {
    if (range === XS) {
      setStyles({
        ...styles,
        titleVariant: "h4",
        bigTitleVariant: "h4",
      });
    }
    if (range === SM) {
      setStyles({
        ...styles,
        titleVariant: "h3",
        bigTitleVariant: "h2",
      });
    }
    if (range === MD) {
      setStyles({
        ...styles,
        titleVariant: "h3",
        bigTitleVariant: "h2",
      });
    }
    if (range >= LG) {
      setStyles({
        ...styles,
        titleVariant: "h2",
        bigTitleVariant: "h1",
        descriptionVariant: "h5"
      });
    }
  }, [range]);

  const onClickMeses = e => {
    let plazo = e.target.innerHTML;
    let plazoIndex = selectedPlan.pagosConAnticipo.findIndex(p => plazo === `${p.plazo} MESES`);
    if (plazoIndex !== -1) {
      setPagoConAnticipo(selectedPlan.pagosConAnticipo[plazoIndex]);
      setPagoSinAnticipo(selectedPlan.pagosSinAnticipo[plazoIndex]);
      setSelectedPlazo(plazo);
      eventTrack('Lista botones plazos', plazo, 'Button');
    }
  }

  const finishContactFormModal = ( name, email, phone, subject, message ) => {
    sessionStorage.setItem('modalContactHidden', 'true');
    showSnackbar("Correo enviado", "success");
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }  

  const finishFormModal = (anticipo, buyerName, buyerEmail, buyerPhone, emailCheck) => {
    let tipoDePlan = anticipo ? pagoConAnticipo : pagoSinAnticipo;

    tipoDePlan.planId = selectedPlan.id;
    tipoDePlan.anticipo = anticipo;
    tipoDePlan.nombreAlternativo = true;

    tipoDePlan.primerPago = tipoDePlan.quincenal.primerPago;
    tipoDePlan.parcialidad = tipoDePlan.quincenal.pago;
    tipoDePlan.total = tipoDePlan.quincenal.total;

    goToCheckout(tipoDePlan, buyerEmail, emailCheck);
  }

  function openContactFormModal(){
    showCustomDialog(
      <ContactFormModal 
        anticipo={true} 
        finishContactFormModal={finishContactFormModal}
        showSnackbar={showSnackbar} 

      />, 
      null, 
      null,
      null,
      null,
      null,
      100);
  }

  const handleConAnticipoClick = event => {
    showCustomDialog(
      <BuyerFormModal 
        anticipo={true} 
        finishFormModal={finishFormModal}
        showSnackbar={showSnackbar} 
      />, 
      null, 
      null,
      null,
      null,
      null,
      100);
      eventTrack('Modal Contacto Venta', 'Abrir Modal (Con anticipo)', 'Button');
  }

  const handleSinAnticipoClick = event => {
    showCustomDialog(
      <BuyerFormModal 
        anticipo={false} 
        finishFormModal={finishFormModal}
        showSnackbar={showSnackbar} 
      />, 
      null, 
      null,
      null,
      null,
      null,
      500);
    eventTrack('Modal Contacto Venta', 'Abrir Modal (Sin anticipo)', 'Button');
  }

  return (
    <ThemeProvider theme={themePrimary}>
      {selectedPlan !== null ? (
        <Box style={{ scrollBehavior: "smooth" }}>

          <Banner/>

          <Box mt={8}/>

          <ScrollAnimation animateIn='bounceInRight' animateOut='bounceOutLeft'>
            <Grid
              container
              direction="row"
              justify="space-evenly"
              alignItems="center"
            >
              <Grid xs={10}
                item
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
              >
                <Grid item>
                  <TextView
                    variant={styles.descriptionVariant}
                    textColor="#326295"
                    fontWeight={400}
                  >
                    {selectedPlan.descripciones.map((des, index) => (
                      <p key={index}>
                        {des}
                      </p>
                    ))}
                  </TextView>
                </Grid>
                <Grid item>
                  <TextView mt={2}
                    variant={styles.titleVariant}
                    textColor="#326295"
                  >
                    PLAN
                  </TextView>
                </Grid>
                <Grid item>
                  <TextView mb={2}
                    variant={styles.bigTitleVariant}
                    textColor="#326295"
                    fontWeight={700}
                  >
                    TRANQUILIDAD
                  </TextView>
                </Grid>
              </Grid>
            </Grid>
          </ScrollAnimation>
          
          <ScrollAnimation animateIn='bounceInRight' animateOut='bounceOutLeft'>
            <Box mt={3} mb={2} mx={6}>
              <Grid
                container 
                direction="row"
                justify="space-around"
                alignItems="flex-start"
              >

                <Grid xs={4} md={1}
                  container 
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  <Box>
                    <img src="/image/planes/tranquilidad/transporte.png" width={48} height={48} alt="" />
                  </Box>
                  <TextView variant="subtitle1" textColor="#326295" align="center" style={{ fontSize: "10px" }}>
                    TRANSPORTE DEL FINADO
                  </TextView>
                  <TextView variant="body2" textColor="#326295" align="center" style={{ fontSize: "8px" }} mb={2}>
                    DE HOSPITAL O DOMICILIO LOCAL A FUNERARIA
                  </TextView>
                </Grid>

                <Grid xs={4} md={1}
                  container 
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  <Box>
                    <img src="/image/planes/tranquilidad/tramites.png" width={48} height={48} alt="" />
                  </Box>
                  <TextView variant="subtitle1" textColor="#326295" align="center" style={{ fontSize: "10px" }}>
                    ASESORÍA DE TRÁMITES
                  </TextView>
                  <TextView variant="body2" textColor="#326295" align="center" style={{ fontSize: "8px" }} mb={2}>
                    NO INCLUYE COSTOS
                  </TextView>
                </Grid>

                <Grid xs={4} md={1}
                  container 
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  <Box>
                    <img src="/image/planes/tranquilidad/embalsamiento.png" width={74} height={30} alt="" />
                  </Box>
                  <TextView variant="subtitle1" textColor="#326295" align="center" style={{ fontSize: "10px" }}>
                    EMBALSAMAMIENTO
                  </TextView>
                  <TextView variant="body2" textColor="#326295" align="center" style={{ fontSize: "8px" }} mb={2}>
                    PREPARACIÓN DE FINADO Y ARREGLO ESTÉTICO
                  </TextView>
                </Grid>

                <Grid xs={4} md={1}
                  container 
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  <Box>
                    <img src="/image/planes/tranquilidad/carroza.png" width={74} height={30} alt="" />
                  </Box>
                  <TextView variant="subtitle1" textColor="#326295" align="center" style={{ fontSize: "10px" }}>
                    SERVICIO DE CARROZA
                  </TextView>
                  <TextView variant="body2" textColor="#326295" align="center" style={{ fontSize: "8px" }} mb={2}>
                    TRASLADO DEL DOMICILIO - IGLESIA - PANTEÓN LOCAL
                  </TextView>
                </Grid>

                <Grid xs={4} md={1}
                  container 
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  <Box>
                    <img src="/image/planes/tranquilidad/ataud.png" width="auto" height={48} alt="" />
                  </Box>
                  <TextView variant="subtitle1" textColor="#326295" align="center" style={{ fontSize: "10px" }}>
                    ATAÚD METÁLICO BÁSICO
                  </TextView>
                  <TextView variant="body2" textColor="#326295" align="center" style={{ fontSize: "8px" }} mb={2}>
                    COLOR A ELEGIR
                  </TextView>
                </Grid>

                <Grid xs={4} md={1}
                  container 
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  <Box>
                    <img src="/image/planes/tranquilidad/cremacion.png" width="auto" height={48} alt="" />
                  </Box>
                  <TextView variant="subtitle1" textColor="#326295" align="center" style={{ fontSize: "10px" }}>
                    SERVICIO DE CREMACIÓN
                  </TextView>
                  <TextView variant="body2" textColor="#326295" align="center" style={{ fontSize: "8px" }} mb={2}>
                    OPCIONAL
                  </TextView>
                </Grid>

                <Grid xs={4} md={1}
                  container 
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  <Box>
                    <img src="/image/planes/tranquilidad/urna.png" width={48} height={48} alt="" />
                  </Box>
                  <TextView variant="subtitle1" textColor="#326295" align="center" style={{ fontSize: "10px" }}>
                    URNA DE MADERA CLÁSICA
                  </TextView>
                  <TextView variant="body2" textColor="#326295" align="center" style={{ fontSize: "8px" }} mb={2}>
                    OPCIONAL
                  </TextView>
                </Grid>
                  
              </Grid>
            </Box>
          </ScrollAnimation>

          <ScrollAnimation animateIn='bounceInRight' animateOut='bounceOutLeft'>
            <Grid
              container 
              direction="row"
              justify="center"
              alignItems="flex-start"
            >
              <TextView variant="body1" textColor="#326295" align="center" style={{ fontSize: "12px" }}>
                PLAN EXCLUSIVO PARA COMPRA EN LÍNEA
              </TextView>
            </Grid>
          </ScrollAnimation>

          <Box mt={10}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={2} sm={4}>
                <Divider 
                  style={{
                    background: "#326295",
                    height: 3
                  }}/>
              </Grid>
              <Grid item>
                <TextView
                  textColor="#326295"
                  textAlign="center"
                  fontWeight={700}
                >
                  PAGOS FLEXIBLES
                </TextView>
              </Grid>
              <Grid item xs={2} sm={4}>
                <Divider 
                  style={{
                    background: "#326295",
                    height: 3
                  }}/>
              </Grid>
            </Grid>
          </Box>

          <Box mt={3}>
            <Box py={2} style={{ background: "#C1DCED" }}>
              <PagosFlexibles
                selectedPlan={selectedPlan}
                selectedButton={selectedPlazo}
                onClickMeses={onClickMeses} />
            </Box>
          </Box>

          <Fade in={selectedPlazo !== ""} timeout={500} mountOnEnter unmountOnExit>
            <Box mb={8}>
              <Grid
                container 
                direction="column"
                justify="center"
                alignItems="center"
                style={{
                  background: "#C1DCED"
                }}
              >

                <Grid xs={7} sm={6}
                  container 
                  direction="column"
                  justify="center"
                  alignItems="flex-start"
                >
                  <Grid item>
                    <TextView
                      textColor="#326295"
                      fontWeight={700}
                      mt={1}
                    >
                      CON ANTICIPO
                    </TextView>
                  </Grid>

                  <Grid item>
                    <TextView
                      textColor="#326295"
                      fontWeight={400}
                      mt={1}
                    >
                      {selectedPlan.porcentajeDescuento}% DE DESCUENTO (AL DOMICILIAR)
                    </TextView>
                  </Grid>
                  <Grid item>
                    <TextView
                      textColor="#326295"
                      fontWeight={400}
                    >
                      ANTES <strike>{money.format(Math.round(pagoConAnticipo.quincenal.total/0.95))}</strike>
                    </TextView>
                  </Grid>
                  <Grid item>
                    <TextView
                      textColor="#326295"
                      fontWeight={700}
                    >
                      AHORA {money.format(pagoConAnticipo.quincenal.total)}
                    </TextView>
                  </Grid>

                  <Grid item>
                    <TextView
                      textColor="#326295"
                      fontWeight={400}
                      mt={1}
                    >
                      ANTICIPO DE {money.format(pagoConAnticipo.quincenal.primerPago)}
                    </TextView>
                  </Grid>
                  <Grid
                    container 
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                  >
                    <Grid item>
                      <TextView
                        textColor="#326295"
                        fontWeight={400}
                        mb={2}
                      >
                        DESDE {money.format(pagoConAnticipo.quincenal.pago)} QUINCENALES
                      </TextView>
                    </Grid>
                    <Grid item>
                      <Button 
                        variant="contained"
                        size="small"
                        color="primary"
                        className="animation"
                        onClick={handleConAnticipoClick}
                        style={{
                          borderRadius: 16
                        }}
                      >
                        COMPRAR PLAN
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>

                <Box width="80%" my={2}>
                  <Divider 
                    style={{
                      background: "#32629533",
                      height: 2
                    }}/>
                </Box>

                <Grid xs={7} sm={6}
                  container 
                  direction="column"
                  justify="center"
                  alignItems="flex-start"
                >
                  <Grid item>
                    <TextView 
                      textColor="#326295"
                      fontWeight={700}
                      mt={1}
                    >
                      SIN ANTICIPO
                    </TextView>
                  </Grid>

                  <Grid item>
                    <TextView
                      textColor="#326295"
                      fontWeight={400}
                      mt={1}
                    >
                      {selectedPlan.porcentajeDescuento}% DE DESCUENTO (AL DOMICILIAR)
                    </TextView>
                  </Grid>
                  <Grid item>
                    <TextView
                      textColor="#326295"
                      fontWeight={400}
                    >
                      ANTES <strike>{money.format(Math.round(pagoSinAnticipo.quincenal.total/0.95))}</strike>
                    </TextView>
                  </Grid>
                  <Grid item>
                    <TextView
                      textColor="#326295"
                      fontWeight={700}
                    >
                      AHORA {money.format(pagoSinAnticipo.quincenal.total)}
                    </TextView>
                  </Grid>
                  
                  <Grid
                    container 
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                  >
                    <Grid item>
                      <TextView
                        textColor="#326295"
                        fontWeight={400}
                        mt={1}
                        mb={2}
                      >
                        DESDE {money.format(pagoSinAnticipo.quincenal.pago)} QUINCENALES
                      </TextView>
                    </Grid>
                    <Grid item>
                      <Button 
                        variant="contained"
                        size="small"
                        color="primary"
                        className="animation"
                        onClick={handleSinAnticipoClick}
                        style={{
                          borderRadius: 16
                        }}
                      >
                        COMPRAR PLAN
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid xs={10} sm={8} md={6}
                  container 
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  <Grid item>

                    </Grid>
                </Grid>

                <Box width={3/4} my={1}>
                </Box>

              </Grid>
            </Box>
          </Fade>

          <ScriptTag type="text/javascript">
            let headID = document.getElementsByTagName("head")[0]; let newCss = document.createElement('link'); newCss.rel = 'stylesheet'; newCss.type = 'text/css'; newCss.href = "https://botmake.io/embed/bot1068451.css"; let newScript = document.createElement('script'); newScript.src = "https://botmake.io/embed/bot1068451.js"; newScript.type = 'text/javascript'; headID.appendChild(newScript); headID.appendChild(newCss);
          </ScriptTag>

          <Footer  showDialog={showCustomDialog}/>

        </Box>
      ) : (
        null
      )}
        
    </ThemeProvider>
  );
}

export default PlanParaisoPage;

/*
        <Button 
          id="planDescripcion" 
          variant="contained" 
          size={styles.buttonSize}
          style={{
            background: '#326295',
            color: "#ffffff"
          }}
          onClick={(e) => {
            e.preventDefault();
            window.open(
              'https://wa.me/+526673170000',
              '_blank'
            );
          }}
        >
          SOLICITAR AYUDA
        </Button>
*/