import axios from "axios";

export const config = {
  debug: process.env.REACT_APP_DEBUG === "true",
  host: process.env.REACT_APP_HOST,
  proxy: process.env.REACT_APP_PROXY_REQUEST,
  lablueApi: process.env.REACT_APP_LABLUE_API,

  mailgunDomain: process.env.REACT_APP_MAILGUN_DOMAIN,
  mailgunApiKey: process.env.REACT_APP_MAILGUN_API_KEY,
  mailgunMail: process.env.REACT_APP_MAILGUN_MAIL,
  contactFormTo: process.env.REACT_APP_CONTACT_FORM_TO,

  contactSeguimientoProspectoFormTo: process.env.REACT_APP_SEGUIMIENTO_PROSPECTO_FORM_TO,

  formCuliacanTo: process.env.REACT_APP_FORM_EMAIL_CULIACAN,
  formLosMochisTo: process.env.REACT_APP_FORM_EMAIL_LOS_MOCHIS,
  formGuasaveTo: process.env.REACT_APP_FORM_EMAIL_GUASAVE,
  formNavolatoTo: process.env.REACT_APP_FORM_EMAIL_NAVOLATO,

  emailReporteVisitasTo: process.env.REACT_APP_EMAIL_REPORTE_VISITAS,

  mercagoPagoPublicKey: process.env.REACT_APP_MP_PUBLIC_KEY,
  mercadoPagoAccessToken: process.env.REACT_APP_MP_ACCESS_TOKEN,

  front: process.env.REACT_APP_FRONT,
  mpApi: process.env.REACT_APP_MP_API
}

var mailgun = require('mailgun-js')({ apiKey: config.mailgunApiKey, domain: config.mailgunDomain });

export async function mpCreatePreference(productName, price, picture) {
  let bodyData = {
    mpAccessToken: config.mercadoPagoAccessToken,
    productName: productName,
    price: price,
    quantity: 1,
    picture: `${config.front}${picture}`,
    backUrls: {
      success: `${config.front}/compra/resumen`,
      failure: `${config.front}/compra/resumen`,
      pending: `${config.front}/compra/resumen`
    }
  }

  let response = await postApiRequest(config.mpApi, 'mercadopago/create-preference', bodyData);

  return response;
}

export async function sendPlanEmail(email, name, city, html) {
  if (config.debug) {
    console.log(`sendPlanEmail(${email}, ${name}, ${html})`);
  }

  let formTo = "";

  switch (city) {
    case 1:
      formTo = config.formCuliacanTo;
      break;
    case 2:
      formTo = config.formLosMochisTo;
      break;
    case 3:
      formTo = config.formGuasaveTo;
      break;
    case 4:
      formTo = config.formNavolatoTo;
      break;
    default:
      formTo = config.formCuliacanTo;
  }

  let data = {
    from: `SM-Web - Simulador de planes <${config.mailgunMail}>`,
    to: formTo,
    subject: `Simulador resultado - ${name}`,
    html: html
  };

  mailgun.messages().send(data, function (error, body) {
    if (config.debug) {
      console.log(body);
      console.log(error);
    }
  });
}

export async function sendCompraResumenGerente(name, city, htmlCliente, htmlPlan) {
  if (config.debug) {
    console.log(`sendCompraResumenGerente()`);
  }

  let formTo = "";

  switch (city) {
    case 1:
      formTo = config.formCuliacanTo;
      break;
    case 2:
      formTo = config.formLosMochisTo;
      break;
    case 3:
      formTo = config.formGuasaveTo;
      break;
    case 4:
      formTo = config.formNavolatoTo;
      break;
    default:
      formTo = config.formCuliacanTo;
  }

  let data = {
    from: `SM-Web - Resumen de Compra <${config.mailgunMail}>`,
    to: formTo,
    subject: `Resumen de Compra - ${name}`,
    html: `
      <h3>Información de Cliente<h3>
      ${htmlCliente}
      <h3>Información de Plan<h3>
      ${htmlPlan}
      <br/>
    `
  };

  mailgun.messages().send(data, function (error, body) {
    if (config.debug) {
      console.log(body);
      console.log(error);
    }
  });
}

export async function sendCompraResumenCliente(email, name, html) {
  if (config.debug) {
    console.log(`sendCompraResumenCliente()`);
  }

  let data = {
    from: `San Martín - Resumen de Compra <${config.mailgunMail}>`,
    to: email,
    subject: 'San Martín - Resumen de Compra',
    html: `
      <h3>Hola ${name}<h3>
      <h3>Gracias por adquirir uno de nuestros Planes Funerarios!<br/><br/>El equipo de ventas se pondrá en contacto lo más pronto posible para continuar con el proceso de firma del contrato de venta.<h3>
      ${html}
      <br/>
    `
  };

  mailgun.messages().send(data, function (error, body) {
    if (config.debug) {
      console.log(body);
      console.log(error);
    }
  });
}

export async function sendPlanEmailToClient(email, name, parque, html) {
  if (config.debug) {
    console.log(`sendPlanEmailToClient(${email}, ${name}, ${html})`);
  }

  let imgPromoParque = `<img width='100%' height='auto' src="https://sanmartin.com.mx/image/email_promo_flyer_parques.jpg" />`;

  let data = {
    from: `San Martín - Plan resultado <${config.mailgunMail}>`,
    to: email,
    subject: 'San Martín - Simulador resultado',
    html: `
      <h2>Hola ${name}<h2>
      <h3>Gracias por usar el Simulador de Planes!<br/>Nos estaremos comunicando lo mas pronto posible.<h3>
      <br/>
      ${html}
      <br/>
    `
    /*  <img width='100%' height='auto' src="https://sanmartin.com.mx/image/email_promo_flyer_junio.jpg" />
      <br/>
      ${parque ? imgPromoParque : ""}
    */
  };

  mailgun.messages().send(data, function (error, body) {
    if (config.debug) {
      console.log(body);
      console.log(error);
    }
  });
}

export async function sendContactUsEmail(email, name, phone, subject, message) {
  if (config.debug) {
    console.log(`sendContactUsEmail(${email}, ${name}, ${phone}, ${subject}, ${message})`);
  }

  let data = {
    from: `SM-Web Contacto <${config.mailgunMail}>`,
    to: config.contactFormTo,
    subject: subject,
    html: `
      <li><b>Nombre:</b> ${name}</li>
      <li><b>Teléfono:</b> ${phone}</li>
      <li><b>Correo:</b> ${email}</li>

      <p>
        ${message}
      </p>
    `
  };

  let clientMessage = {
    from: `San Martín - Contacto <${config.mailgunMail}>`,
    to: email,
    subject: 'Autorespuesta',
    html: `
      <h2>Hola ${name}<h2>
      <h3>Gracias por contactarnos!<br/>Nos estaremos comunicando lo mas pronto posible.<h3>
      <br/>
    `
    //<img width='100%' height='auto' src="https://sanmartin.com.mx/image/email_promo_flyer_junio.jpg" />
  };

  mailgun.messages().send(data, function (error, body) {
    if (config.debug) {
      console.log(body);
      console.log(error);
    }
  });
  mailgun.messages().send(clientMessage, function (error, body) {
    if (config.debug) {
      console.log(body);
      console.log(error);
    }
  });
}

export async function sendProspectoEmail(email, name, phone, htmlPlan) {
  if (config.debug) {
    console.log(`sendProspectoEmail(${email}, ${name}, ${phone}, ${htmlPlan})`);
  }

  let data = {
    from: `Seguimiento Posible Prospecto <${config.mailgunMail}>`,
    to: config.contactSeguimientoProspectoFormTo,
    subject: 'Seguimiento - Posible Prospecto',
    html: `
      <h3>Información del Prospecto: <h3>
        <ul>
          <li><b>Nombre:</b> ${name}</li>
          <li><b>Teléfono:</b> ${phone}</li>
          <li><b>Correo:</b> ${email}</li>
        </ul>
        <br>
      <h3>Plan de interes para el Prospecto: <h3>
      ${htmlPlan}
    `
  };

  mailgun.messages().send(data, function (error, body) {
    if (config.debug) {
      console.log(body);
      console.log(error);
    }
  });
}

export async function obtenerObituarios() {
  return await getRequest2(config.lablueApi + 'obituario/getObituario/1');
}

export async function consultarContrato(folioContrato, idSistema) {
  return await postRequest(config.lablueApi + 'payment/consultcontract', {
    folioContrato: folioContrato,
    idSistema: idSistema
  });

}
export async function consultarContratoMtto(folioContrato, idSistema) {
  return await postRequest(config.lablueApi + 'payment/maintenancecontractinfo', {
    folioContrato: folioContrato,
    idSistema: idSistema
  });

}
export async function ValidaPagoPendiente(idContrato = 0, idSistema) {
  return await postRequest(config.lablueApi + 'payment/hasNoPendingPayment', {
    idContrato: idContrato,
    idSistema: idSistema
  });

}

export async function ValidaPagoSinOrden(idContrato = 0, idSistema) {
  return await postRequest(config.lablueApi + 'payment/contractHasOrder', {
    idContrato: idContrato,
    idSistema: idSistema
  });

}

export async function consultarPagos(idContrato = 0, idSistema) {
  return await postRequest(config.lablueApi + 'payment/consultgeneralpayments', {
    idContrato: idContrato,
    idSistema: idSistema
  });
}

export async function InicioSesion(folioContrato, Nip) {
  const data = {
    folioContrato: folioContrato,
    Nip: Nip
  }
  return await postRequest(config.lablueApi + 'login/auth', data);
}
export async function ValidaHorarioPago() {
  return await getRequest(config.lablueApi + 'PaymentSchedule/validPaidHour');
}
export async function ValidaHorarioPago2() {
  return await getRequest2(config.lablueApi + 'PaymentSchedule/validPaidHour');
}

export async function HorariosPagoEnLinea() {
  return await getRequest(config.lablueApi + 'PaymentSchedule');
}


export async function guardarNip(folioContrato = 0, Nip = 0, correo = '', idContrato = 0, idCliente = 0) {
  return await postRequest(config.lablueApi + 'user', {
    Nip: Nip,
    correo: correo,
    folioContrato: folioContrato
  });
}

export async function estadoDeCuenta(folioContrato = 0, idSistema) {
  return await postRequest(config.lablueApi + 'payment/account', {
    folioContrato: folioContrato,
    idSistema: idSistema
  });
}

export async function generarLinkDePago(folioContrato = 0, idContrato = 0, idSistema = 0, concepto = '', importe = 0, jsonParcialidades, tipo) {
  return await postRequest(config.lablueApi + 'payment/generatelink', {
    conceptoDePago: concepto,
    importe: importe,
    folioContrato: folioContrato,
    idContrato: idContrato,
    idSistema: idSistema,
    jsonParcialidades: jsonParcialidades,
    tipo: tipo
  });
}
export async function contractList(idSistema = 0, idCliente = '') {
  return await postRequest(config.lablueApi + 'payment/listContractsClient', {
    idCliente: idCliente,
    idSistema: idSistema
  });
}

export async function consultarPagoRealizado(idPago = 0, clavePago = '', tokenPago = '', objContrato, arrParcialidades) {
  return await postRequest(config.lablueApi + 'payment/generatepay', {
    idPago: idPago
  });
}


export async function getRequest(endpoint) {
  return new Promise(async (resolve, reject) => {
    try{
      const options = {
        method: 'GET',
        url: endpoint,
        headers: {
          'Content-Type': 'application/json',
        }
      };
      await axios(options)
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          resolve(response.data);  // Devuelve los datos de la respuesta
        } else {
          reject(`Request failed with status code: ${response.status}`);
        }
      })
    }catch (error) {
      console.log(error)
  
      const currentPath = window.location.pathname;
      localStorage.setItem("ReturnPage", currentPath)
      if (error.code === 'ERR_NETWORK') {
        console.error('Network Error:', error.message);
        // Redirigir al usuario en caso de error de red
        window.location.href = '/FueradeLinea';
      } else if (error.response?.status === 404) {
        console.error('Not Found:', error.response?.data);
        // Redirigir si el servidor responde con 404
        window.location.href = '/FueradeLinea';
      } else {
        console.error('Error:', error.response?.data || error.message);
        throw error.response?.data || error.message || 'An unknown error occurred';
      }
    }

  });
}
export async function getRequest2(endpoint) {
  let body = {
    _api: config.lablueApi,
    _endpoint: endpoint,
    _method: 'GET'
  }

  let sentData = {
    method: 'GET',
    header: {
      'Content-Type': 'application/json'
    }
  };

  let response = await fetch(endpoint, sentData)
    .then(res => {
      if (res.status === 200) {
        return res.json().then(data => ({ status: res.status, data: data }));
      } 
    })
    .catch(error => ({ status: 'error', error: error.message }));

  return response;
}
export async function postRequest(endpoint, data) {
  try {
    //console.log(endpoint, data);
    const response = await axios.post(endpoint, data, {
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: false,
      mode: 'no-cors',
    });

    if (response.status >= 200 && response.status < 300) {
      return response.data;
    } else {
      throw new Error(`Request failed with status code: ${response.status}`);
    }
  } catch (error) {
    console.log(error)

    const currentPath = window.location.pathname;
    localStorage.setItem("ReturnPage", currentPath)
    if (error.code === 'ERR_NETWORK') {
      console.error('Network Error:', error.message);
      // Redirigir al usuario en caso de error de red
      window.location.href = '/FueradeLinea';
    } else if (error.response?.status === 404) {
      console.error('Not Found:', error.response?.data);
      // Redirigir si el servidor responde con 404
      window.location.href = '/FueradeLinea';
    } else {
      console.error('Error:', error.response?.data || error.message);
      throw error.response?.data || error.message || 'An unknown error occurred';
    }
  }
}

export async function postApiRequest(api, endpoint, data) {
  let body = {
    _api: api,
    _endpoint: endpoint,
    _method: 'POST',
    ...data
  }

  let sentData = {
    method: 'POST',
    header: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };

  let response = await fetch(config.proxy, sentData)
    .then(res => res.json())
    .then(data => data)
    .catch(error => error);

  return response;
}

export async function sendReporteVisitasEmail(visitas) {
  let formTo = config.emailReporteVisitasTo;
  let htmlVisitas = '';
  let totalVisitas = 0;
  let totalVisitasUnicas = 0;

  for (let i = visitas.length - 1; i >= 0; i--) {
    let day = visitas[i].day;
    let pages = visitas[i].pages;
    let diaVisitas = 0;
    let diaVisitasUnicas = 0;

    htmlVisitas += `<br>
      <div class="ritz grid-container" dir="ltr">
        <table class="waffle" cellspacing="0" cellpadding="0">
          <tbody>
            <tr style="height: 20px">
              <td class="s0" dir="ltr" colspan="3">${formatDate(day)}</td>
            </tr>
            <tr style="height: 20px">
              <td class="s0" dir="ltr">Sección</td>
              <td class="s0" dir="ltr">Visitas</td>
              <td class="s0" dir="ltr">Visitas únicas</td>
            </tr>
    `;

    for (let j = 0; j < pages.length; j++) {
      let itemPage = pages[j];
      htmlVisitas += `
        <tr style="height: 20px">
          <td class="s1" dir="ltr">${itemPage.id}</td>
          <td class="s1" dir="ltr">${itemPage.total}</td>
          <td class="s1" dir="ltr">${itemPage.totalUnicas}</td>
        </tr>
      `;
      diaVisitas += itemPage.total;
      diaVisitasUnicas += itemPage.totalUnicas;
    }

    htmlVisitas += `
            <tr style="height: 20px">
              <td class="s1" dir="ltr">TOTAL</td>
              <td class="s1" dir="ltr">${diaVisitas}</td>
              <td class="s1" dir="ltr">${diaVisitasUnicas}</td>
            </tr>
          </tbody>
        </table>
      </div>
    `;

    totalVisitas += diaVisitas;
    totalVisitasUnicas += diaVisitasUnicas;
  }

  htmlVisitas = `<br>
      <div class="ritz grid-container" dir="ltr">
        <table class="waffle" cellspacing="0" cellpadding="0">
          <tbody>
            <tr style="height: 20px">
              <td class="s0" dir="ltr" colspan="3">
              ${formatDate(visitas[visitas.length - 1].day)} al ${formatDate(visitas[0].day)}
              </td>
            </tr>
            <tr style="height: 20px">
              <td class="s0" dir="ltr">Sección</td>
              <td class="s0" dir="ltr">Visitas</td>
              <td class="s0" dir="ltr">Visitas únicas</td>
            </tr>
            <tr style="height: 20px">
              <td class="s1" dir="ltr">TODO</td>
              <td class="s1" dir="ltr">${totalVisitas}</td>
              <td class="s1" dir="ltr">${totalVisitasUnicas}</td>
            </tr>
          </tbody>
        </table>
      </div>
    ` + htmlVisitas;

  let data = {
    from: `SM-Web - Analítica <${config.mailgunMail}>`,
    to: formTo,
    subject: `Analítica de web San Martín`,
    html: `
    <html>
    <head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
    <style type="text/css">
      .ritz .waffle a {
        color: inherit;
      }
    
      .ritz .waffle .s1 {
        border-bottom: 1px SOLID #000000;
        border-right: 1px SOLID #000000;
        background-color: #ffffff;
        text-align: center;
        color: #000000;
        font-family: 'Arial';
        font-size: 10pt;
        vertical-align: bottom;
        white-space: nowrap;
        direction: ltr;
        padding: 2px 3px 2px 3px;
      }
    
      .ritz .waffle .s0 {
        border-bottom: 1px SOLID #000000;
        border-right: 1px SOLID #000000;
        background-color: #a4c2f4;
        text-align: center;
        font-weight: bold;
        color: #ffffff;
        font-family: 'Arial';
        font-size: 12pt;
        vertical-align: bottom;
        white-space: nowrap;
        direction: ltr;
        padding: 2px 3px 2px 3px;
      }
    </style>
    </head>
    <body>
      ${htmlVisitas}
    </body>
    </html>
    `
  };

  mailgun.messages().send(data, function (error, body) {
    if (config.debug) {
      console.log(body);
      console.log(error);
    }
  });
}

function formatDate(date) {
  return `${date.substr(8, 2)}/${date.substr(5, 2)}/${date.substr(0, 4)}`;
}