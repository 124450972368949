import React from 'react';

// @material-ui/core components
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import { InicioSesion, guardarNip, ValidaHorarioPago } from "../../../Api";
import { ca } from 'date-fns/locale';

// Constant
const FOLIO_MIN = 1;
const FOLIO_MAX = 10;
const NIP_MIN = 4;
const NIP_MAX = 4;
const EMAIL_MIN = 6;
const EMAIL_MAX = 64;
const NUMBER_PATTERN = /^[0-9]+$/;
const EMAIL_PATTERN = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

const numTelefono = ', favor de ponerse en contacto con atención al cliente al número (667) 716-59-39 en horario de oficina'

export async function loadUserData(folio = 0, checkStatus = false, showSnackbar) {
  let response = null


  return response;
}


async function userLogin(folioContrato = 0, NipDeAcceso = 0, setAllEnabled, showSnackbar) {
 
  try {
    let response = await InicioSesion(folioContrato, NipDeAcceso);
    let estatus = response.data.estatus.deEstatus;
    let aplicaMTTO = response.data.aplicaMTTO;
    let Valor = response.isValid;

    if (!Valor) {
      showSnackbar('Folio de contrato o Nip de acceso incorrecto favor de validar' + numTelefono, 'error');
    } else if (!estatus || estatus == null || estatus === "") {
      showSnackbar('Error al Consultar el folio del contrato' + numTelefono, 'error');
    }

    if (estatus === "SUSPENDIDO") {
      showSnackbar('El folio de contrato que esta consultado se encuentra SUSPENDIDO' + numTelefono, 'Warning');
    } else if (estatus === "CANCELADO") {
      showSnackbar('El folio de contrato que esta consultado se encuentra CANCELADO' + numTelefono, 'error');
    } else if (estatus != "AUTORIZADO" && estatus != "PAGADO" && Valor) {
      showSnackbar('El folio de contrato que esta consultado no se encuentra disponible para pago' + numTelefono, 'error');
    }

    let SessionData = {
      folioContrato: folioContrato,
      estatus: estatus,
      sesion: true,
      idSistema: response.data.idSistema,
      aplicaMTTO: aplicaMTTO,
      contratos: response.data.contratos,
      idCliente: response.data.estatus.idCliente,
    }
    localStorage.setItem('user', JSON.stringify(SessionData));

    if (Valor && estatus == "AUTORIZADO" && response.data.contratos.length == 1) {
      window.location.href = '/payment/details'
    } else if (Valor && estatus == "PAGADO" && response.data.contratos.length == 1 ) {
      window.location.href = aplicaMTTO? '/payment/details/mtto': '/payment/details/list'
    } else if (Valor && (estatus == "PAGADO" || estatus == "AUTORIZADO")&& response.data.contratos.length > 1) {
      window.location.href = '/payment/details/list'
    }
  } catch (e) {
    showSnackbar('Error al Consultar el folio del contrato' + numTelefono, 'error');
  }
}


async function setNewNip(form, updateForm, setAllFormVisible, setAllEnabled, showSnackbar) {
  setAllEnabled(false);
  let response = await guardarNip(
    form.folioValue,
    form.nipValue,
    form.emailValue);

  setAllEnabled(true);

  if (response.error) {
    showSnackbar(response.message, 'error');
    return;
  }
  //if (response.data.contador>0){
  //  showSnackbar("Ya se encuentra un NIP generado"+numTelefono, "error");
  //  return;
  //}
  if (response.data.deEstatus) {
    updateForm({
      folioValue: form.folioValue,
      nipValue: '',
      confirmNipValue: '',
      emailValue: ''
    });
    setAllFormVisible(false);
    showSnackbar("NIP creado con éxito, ya puedes ingresar con tu NIP", "success");
  } else {
    showSnackbar("Ocurrió un error al intentar crear el NIP, favor de intentar de nuevo más tarde", "error");
  }
}

function LoginSection({ showSnackbar }) {
  const [isAllEnabled, setAllEnabled] = React.useState(true);
  const [isButtonLoginEnabled, setButtonLoginEnabled] = React.useState(false);
  const [isAllFormVisible, setAllFormVisible] = React.useState(false);
  const [isButtonSaveEnabled, setButtonSaveEnabled] = React.useState(false);
  const [confirmNipError, setConfirmNipError] = React.useState(false);
  const [emailError, setEmailError] = React.useState(false);
  const [form, updateForm] = React.useState({
    folioValue: '',
    nipValue: '',
    confirmNipValue: '',
    emailValue: ''
  });

  const handleInputChange = event => {
    let newValue = event.target.value;

    if (confirmNipError && event.target.name === 'confirmNipValue') {
      setConfirmNipError(false);
    }

    if (emailError && event.target.name === 'emailValue') {
      setEmailError(false);
    }

    if (event.target.name !== 'emailValue' &&
      !NUMBER_PATTERN.test(newValue) &&
      newValue !== '') {
      newValue = form[event.target.name];
    }

    if (event.target.name === 'folioValue' &&
      newValue.length > FOLIO_MAX) {
      newValue = form[event.target.name];
    }

    if ((event.target.name === 'nipValue' ||
      event.target.name === 'confirmNipValue') &&
      newValue.length > NIP_MAX) {
      newValue = form[event.target.name];
    }

    updateForm({
      ...form,
      [event.target.name]: newValue,
    });
  }

  const updateButtonStates = () => {
    let loginEnabled =
      form.folioValue.length >= FOLIO_MIN &&
      form.folioValue.length <= FOLIO_MAX &&
      form.nipValue.length >= NIP_MIN &&
      form.nipValue.length <= NIP_MAX;

    setButtonLoginEnabled(loginEnabled);
    setButtonSaveEnabled(
      loginEnabled &&
      form.confirmNipValue.length >= NIP_MIN &&
      form.confirmNipValue.length <= NIP_MAX &&
      form.emailValue.length >= EMAIL_MIN &&
      form.emailValue.length <= EMAIL_MAX);
  }

  React.useEffect(updateButtonStates, [form]);

  React.useEffect(async () => {
    await ValidaHorarioPago().then((data) => {
      if (!data) {
        const currentPath = window.location.pathname;
        localStorage.setItem("ReturnPage", currentPath)
        window.location.href = '/fueradeHorario'
      } else {
        setAllEnabled(true);
      }
    })
  }, []);

  return (
    <Grid item container xs={12} md={6} direction="column" justifyContent="center" spacing={2}>
      <Grid item>
        <TextField name="folioValue" value={form.folioValue} label="Folio de contrato" variant="outlined" inputmode="numeric" fullWidth disabled={!isAllEnabled} onChange={handleInputChange} />
      </Grid>

      <Grid item>
        <TextField name="nipValue" value={form.nipValue} label="NIP" type="password" inputmode="numeric" variant="outlined" fullWidth disabled={!isAllEnabled} onChange={handleInputChange}
        />
      </Grid>

      <LoginButtons isAllEnabled={isAllEnabled} enabled={isButtonLoginEnabled} visible={!isAllFormVisible}
        onNewUserClick={(event) => {
          setAllFormVisible(true);
        }}
        onLoginClick={() => {
          //manteinance(showSnackbar);
          userLogin(form.folioValue, form.nipValue, setAllEnabled, showSnackbar);
        }}
      />

      <NewUserInputs
        confirmNipValue={form.confirmNipValue}
        emailValue={form.emailValue}
        isAllEnabled={isAllEnabled}
        visible={isAllFormVisible}
        confirmNipError={confirmNipError}
        emailError={emailError}
        onChange={handleInputChange}
      />

      <ActionButtons
        isAllEnabled={isAllEnabled}
        visible={isAllFormVisible}
        saveEnabled={isButtonSaveEnabled}
        onBackClick={(event) => {
          updateForm({ ...form, confirmNipValue: '', emailValue: '' });
          setAllFormVisible(false);
        }}
        onSaveClick={(event) => {
          if (form.confirmNipValue !== form.nipValue) {
            setConfirmNipError(true);
            showSnackbar("NIP y Confirmar NIP deben ser iguales", "error");
            return;
          }

          if (!form.emailValue.match(EMAIL_PATTERN)) {
            setEmailError(true);
            showSnackbar("Debe ingresar un correo válido", "error");
            return;
          }

          //setAllEnabled(false);
          setNewNip(form, updateForm, setAllFormVisible, setAllEnabled, showSnackbar);
        }}
      />

    </Grid>
  );
}

function LoginButtons({ enabled, visible, onNewUserClick, onLoginClick, isAllEnabled }) {
  return (
    <React.Fragment>
      {visible ? (
        <Grid item container direction="row">

          <Grid item container xs direction="row" justifyContent="flex-start">
            <Button variant={"text"} color="primary" disabled={!isAllEnabled} onClick={onNewUserClick}>
              Crear NIP
            </Button>
          </Grid>

          <Grid item container xs direction="row" justifyContent="flex-end">
            <Button variant={enabled ? "contained" : "outlined"} color="primary" disabled={!enabled || !isAllEnabled} onClick={onLoginClick}>
              Ingresar
            </Button>
          </Grid>

        </Grid>
      ) : (
        null
      )}
    </React.Fragment>
  );
}

function NewUserInputs({ confirmNipValue, emailValue, visible, confirmNipError, emailError, onChange, isAllEnabled }) {
  return (
    <React.Fragment>
      {visible ? (
        <React.Fragment>
          <Grid item>
            <TextField name="confirmNipValue" value={confirmNipValue} label="Confirmar NIP" type="password" inputmode="numeric" variant="outlined" fullWidth disabled={!isAllEnabled} error={confirmNipError} onChange={onChange} />
          </Grid>
          <Grid item>
            <TextField name="emailValue" value={emailValue} label="Correo electrónico" type="email" variant="outlined" fullWidth disabled={!isAllEnabled} error={emailError} onChange={onChange} />
          </Grid>
        </React.Fragment>
      ) : (
        null
      )}
    </React.Fragment>
  );
}

function ActionButtons({ visible, saveEnabled, onBackClick, onSaveClick, isAllEnabled }) {
  return (
    <React.Fragment>
      {visible ? (
        <Grid item container direction="row" justifyContent="flex-end" spacing={2} >
          <Grid item>
            <Button variant="text" color="primary" disabled={!isAllEnabled} onClick={onBackClick}>
              Atrás
            </Button>
          </Grid>
          <Grid item>
            <Button variant={saveEnabled ? "contained" : "outlined"} color="primary" disabled={!saveEnabled || !isAllEnabled} onClick={onSaveClick}>
              Guardar
            </Button>
          </Grid>
        </Grid>
      ) : (
        null
      )}
    </React.Fragment>
  );
}

export default LoginSection;
