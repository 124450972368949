import React from 'react';

// @material-ui/core components
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Hidden from '@material-ui/core/Hidden';

// Sections
import TitleSection from './sections/TitleSection';
import InstructionSection from './sections/InstructionSection';
import LoginSection from './sections/LoginSection';
import AvisoPrivacidad from './sections/AvisoPrivacidad';

// Views
import Footer from '../../views/Footer'
import { set } from 'date-fns';

function PaymentLoginPage({ showSnackbar, showDialog }) {
  const userSession = JSON.parse(localStorage.getItem('user'));
  const [user, setUser] = React.useState(userSession ? userSession : {});
  React.useEffect(() => {
    if (user.folioContrato != null && user.folioContrato !== '' && user.folioContrato !== '0') {
      if ((user.estatus === 'PAGADO' || user.estatus === 'AUTORIZADO')   && user.contratos.length > 1) {
          window.location.href = '/payment/details/List'
      } else if (user.estatus === 'PAGADO' && user.contratos.length == 1 ) {
          window.location.href = user.aplicaMTTO? '/payment/details/mtto': '/payment/details/List'
      } else if (user.estatus === 'AUTORIZADO' && user.contratos.length == 1) {
        window.location.href = '/payment/details'
      } else {
        localStorage.clear();
        userSession = null;
        setUser({});
      }
    }
  }, [user]);

  return (
    <Box>
      {userSession && userSession.folioContrato ?
        null
        :
        <Grid component="div">
          <Box mt={12}>
            <Grid container direction="column" justifyContent="center">
              <Box m={4}>
                <TitleSection />
              </Box>

              <Box m={4}>
                <Grid item container direction="row" justifyContent="center" alignItems="flex-start" spacing={8}>
                  <Hidden smDown>
                    <InstructionSection />
                  </Hidden>

                  <LoginSection showSnackbar={showSnackbar} />

                  <Hidden mdUp>
                    <InstructionSection />
                  </Hidden>
                </Grid>
              </Box>

            </Grid>

            <Box mt={8} mb={12} style={{ fontFamily: 'Helvetica Neue' }}>
              <Grid container direction="column" justifyContent="center" alignItems="center">
                <Button
                  variant="text"
                  color="primary"
                  onClick={() => {
                    showDialog(
                      <AvisoPrivacidad />,
                      <b>AVISO DE PRIVACIDAD GRUPO SAN MARTÍN</b>,
                      "Aceptar");
                  }}
                >
                  Aviso de privacidad
                </Button>
              </Grid>
            </Box>

          </Box>
          <Footer showDialog={showDialog}/>
        </Grid>
      }
    </Box>
  );
}

export default PaymentLoginPage;