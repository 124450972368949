import React from 'react';

// @material-ui/core components
import Box from "@material-ui/core/Box";

function AvisoPrivacidad() {
  return (
    <Box style={{ fontFamily: 'Helvetica Neue' }}>
      <p style={{ textAlign: "justify" }}>
        <b>PRIMERO.</b> RESPONSABLE: SAN MARTIN GRUPO DESARROLLADOR INMOBILIARIO S.A. DE C.V., es la persona responsable de recabar los datos personales, del uso que se le dé a los mismos y de su protección. Asimismo, será la persona que dará trámite a las solicitudes de los titulares para el ejercicio de los derechos ARCO (Derechos de Acceso, Rectificación, Cancelación y Oposición, todos ellos relacionados con el tratamiento de datos personales) a que se refiere la Ley. En lo sucesivo se le denominará “Grupo San Martín” quien tiene su domicilio en RUBÍ SUR 836, ENTRE BLVD. ZAPATA Y RÍO HUMAYA, COLONIA GUADALUPE, CULIACÁN, SINALOA,C.P. 80220.
      </p>
      <p style={{ textAlign: "justify" }}>
        El presente aviso de Privacidad tiene por objeto informarle sobre la confidencialidad, y el tratamiento que se dará a sus datos personales recabados en términos del a Ley Federal de Protección de Datos Personales, en Posesión de Particulares y su Reglamento, a la que en lo sucesivo nos referiremos como (la Ley).
      </p>
      <p style={{ textAlign: "justify" }}>
        <b>SEGUNDA.</b> PERTINENCIA DE LOS DATOS.- La información que se obtenga de usted la utilizaremos para las siguientes finalidades que son necesarias para el servicio, se presume pertinente, correcta, actualizada y útil para los fines a los cuales se recaba y se destinan. El titular es el único responsable de la veracidad y la idoneidad de la información que se brinde al Responsable.
      </p>
      <p style={{ textAlign: "justify" }}>
        <b>TERCERA.</b> FINALIDADES. Su información personal será utilizada para proveer los servicios y productos que ha solicitado, informarle sobre cambios en los mismos y evaluar la calidad del servicio que le brindamos. Asimismo, se obtienen con fines de prestación de servicios y productos contratados, mercadotécnicos, publicitarios o de prospección comercial, para con el titular, es decir, para informar a los titulares acerca de los materiales de difusión, promociones, cambios en los productos, ofertas, estudios de mercado; así como para fines administrativos tendientes a evaluar y mejorar la calidad de los servicios que se prestan.
      </p>
      <p style={{ textAlign: "justify" }}>
        <b>CUARTA.</b> DATOS PERSONALES.- Para cumplir con las finalidades antes mencionadas, Grupo San Martín, puede, en su caso, llegar a requerir del titular los siguientes datos personales y/o datos personales sensibles contenidos en el artículo 3 fracción VI:
      </p>
      <p style={{ textAlign: "justify" }}>
        A) DATOS PERSONALES DE ASPIRANTES La recolección y Tratamiento de sus datos personales Grupo San Martín o que pueda identificarse como personales. Nombre, apellidos, domicilio, teléfonos, correos electrónicos, Identificación, CURP, Registro Federal de Contribuyentes, Experiencia Laboral, estado civil, lugar de nacimiento, nacionalidad, Último Grado de Escolaridad, Afore, clave de Seguridad Social, comprobante de domicilio, licencia de conducir(en caso de que aplique), datos de crédito INFONAVIT. (En caso de que aplique, experiencia laboral y/o cargo que ocupa actualmente o anterior, así como información particular en el campo, ramo o profesión a la que aspire o solicite, de igual forma se le informa que los datos patrimoniales y financieros serán recopilados única y exclusivamente en caso de ser acreditado para el cargo solicitado, esta información será recopilada por la Coordinación de Reclutamiento y selección, serán utilizados únicamente para su evaluación, selección, seguimiento y en su caso contratación; a través del formato de solicitud de Recursos Humanos o solicitud de empleo y su cotejo de los documentos originales con su copias certificadas o simples de los documentos en los que conste su información, dentro de la documentación que se solicitara será i) Credencial para Votar, ii) Cartilla del Servicio Militar, iii) Cedula Fiscal, iv) comprobante de domicilio, v) Número de Seguridad Social, vi) cédula profesional; Así mismo usted ha dado su consentimiento para compartir su información personal para solicitar referencias laborales y familiares.
      </p>
      <p style={{ textAlign: "justify" }}>
        La información será usada para el proceso de calificación, selección y en su caso de contratación por parte de Grupo San Martín
      </p>
      <p style={{ textAlign: "justify" }}>
        B) DATOS PERSONALES DE EMPLEADOS Grupo San Martín podrá recabar y en su caso proceder al tratamiento de los datos personales de sus empleados en base a la relación contractual existente entre ambos, dentro de los datos y documentos que se recaben serán los siguientes: i) Nombre, apellidos, Edad y domicilio completo, ii) copia de identificación oficial con fotografía (credencial para votar INE., Pasaporte, cartilla Militar, Licencia de conducir, Cedula Profesional y/o cédula Migratoria), iii), Copia de acta de Nacimiento, iv) copia de comprobante de domicilio, (recibo de Cuenta Catastral, v) copia de recibo suministro de energía eléctrica y/o Servicio telefónico, los cuales se solicitarán con una antigüedad máxima de tres meses), vi) estado civil acta de matrimonio (en caso de que aplique), vii) correo electrónico (en caso de contar con este), viii) número de teléfono particular o de trabajo, así como móvil , y extensión (en caso que aplique), ix) Registro Federal de Contribuyentes, x) Clave Única de Registro de Población, xi) Número de Registro de Seguridad Social, xii) información del Fondo para el Retiro (Afore). Xiii) Se solicitara copias de constancias, certificados de estudios básicos Universitarios, técnicos y de especialidad, xiv) datos del empleo anterior como currículum vitae, xvii)
        <br />copia y constancia de alta o baja ante el Instituto Mexicano del Seguro Social, xix) monto del último salario percibido así como el que aspira, xx) fotografías, referencias personales, xxi) datos de localización y de contacto en caso de emergencia, xxii) datos de beneficiarios o dependientes económicos (nombre completo, parentesco, fecha de nacimiento y edad), xxiii) constancias de empleos anteriores, y autorización para confirmar la autenticidad de la información suministrada de empleos anteriores.
      </p>
      <p style={{ textAlign: "justify" }}>
        Grupo San Martín podrá recabar información y datos sensibles, como: i) estado de salud, si padece o padeció alguna enfermedad, ii) si padece alergias, iii) tipo sanguíneo, iv) si ha sufrido accidentes en su persona, v) resultados de exámenes médicos, vi) constancias médicas, vii) tratamientos a los que esté o haya estado sujeto, y viii) datos financieros para el pago de Nómina.
      </p>
      <p style={{ textAlign: "justify" }}>
        La información que se recaba es y se podrá tratar con la finalidad de formar su expediente laboral, y llevar a cabo el objeto del contrato individual o colectivo en su caso de trabajo, en específico para llevar a cabo el objeto de la relación que surja o exista con usted.
      </p>
      <p style={{ textAlign: "justify" }}>
        Lo anterior en la medida que la Ley lo permite, para cumplir con el objeto y obligaciones de la relación Laboral que se cree o exista con usted, administración de personal y cumplimiento a las obligaciones contenidas en la Ley Federal del Trabajo, y aplicables a las relaciones obrero patronales.
      </p>
      <p style={{ textAlign: "justify" }}>
        Formando el expediente personal que constará de la información descrita y , pago de salario, nómina, bonos y comisiones (en caso de ser aplicable al puesto o cargo desempeñado o a desempeñar), reembolsos pensiones, contrato de seguros, documentar y asignar activos y herramientas de trabajo y de comunicación, auditorías y procesos internos, creación de correo electrónico de la empresa, asignación de claves contraseñas, inclusión en directorio de la empresa, confirmación de datos en empleos anteriores, tener sus antecedentes médicos, para caso de emergencia y contactar a familiares, dependientes o beneficiarios en el mismo supuesto, asegurar el cumplimiento a las obligaciones de confidencialidad, cumplimiento a la Ley federal del Trabajo y obligaciones obrero Patronales.
      </p>
      <p style={{ textAlign: "justify" }}>
        C) DATOS PERSONALES PARA PROVEEDORES Grupo San Martín, podrá recabar la información que se detalla a continuación; i) nombre con apellidos, ii) dirección y ubicación del establecimiento o donde funja el principal asiento de sus negocios, iii) número de teléfono, iv) número de teléfono móvil, v) medios de comunicación, vi) datos bancarios, ( a fin de llevar a cabo el pago de los bienes y servicios contratados), vii) Registro Federal de Contribuyentes, viii) información de Facturación conforme a la legislación vigente aplicable, ix) información de representantes o apoderados factores o dependientes, y x) correo electrónico, xi) Registró Federal de Contribuyentes.
      </p>
      <p style={{ textAlign: "justify" }}>
        Para el caso de personas morales se recabará información conforme a Legislación vigente y aplicable, y en materia de datos la que corresponde a los representantes, factores y dependientes, como i) nombre completo, ii) identificación oficial, y iii) testimonio en las que consten las facultades referidas, dentro de los documentos que se pueden requerir que contenga información de personas físicas es: a) acta constitutiva registrada debidamente inscrita en el Registro Público de la Propiedad y Comercio, b) correo electrónico, c) número telefónico, d) número telefónico móvil, e) identificación oficial, f) comprobante de domicilio, g) actualización de datos por cualquier medio señalado.
      </p>
      <p style={{ textAlign: "justify" }}>
        La información proporcionada será utilizada por Grupo San Martín, para verificar si cumplen con los requisitos necesarios para contratar sus productos y servicios como proveedor si cuenta con la capacidad de servicios, crear expediente de proveedor y datos para pedidos y pagos de los productos o servicios, referir a la información o estatus de pedidos y pagos, celebrar convenios y contratos, y en general para cumplir el objeto de la relación que se genere para con usted en calidad de proveedor se llegue a crear o exista previamente. Así como para cumplir las obligaciones que se generen de la relación contractual.
      </p>
      <p style={{ textAlign: "justify" }}>
        De igual forma la finalidad y uso que se puede dar a sus en la medida que la Ley lo permita se podrán tratar los datos , y pueden ser usados para crear su cuenta y alta de proveedores con Grupo San Martín, emisión de órdenes de compra, seguimiento de pedidos, procedimiento de pagos, recepción y envío de facturas, confirmación de expedientes de proveedores, gestión de padrón de proveedores, y referencias contables, calificación de servicio de proveedores información relacionada con Grupo San Martín, relacionada con sus productos o servicios, con fines mercadotécnicos o de prospección comercial.
      </p>
      <p style={{ textAlign: "justify" }}>
        La información podrá ser tratada con terceros, asociados afiliados dentro del territorio nacional, con la finalidad para emisión de órdenes de compras, para emisión de facturas, formación de base de datos de proveedores, la información financiera la realizar pagos de Servicios y/o prestados o suministrados ya sea en Banco o mediante Transferencia Electrónica.
      </p>
      <p style={{ textAlign: "justify" }}>
        D) DATOS PERSONALES CLIENTE CONSUMIDOR Grupo San Martín podrá recabar la información respecto de usted como son: i) nombre y apellidos, ii) fecha de nacimiento, iii) domicilio, (calle, número, colonia, Delegación o Municipio, entidad o Estado, código postal, nacionalidad), iv) número telefónico particular, v) número telefónico del lugar de trabajo y móvil, o extensión en caso de ser aplicable, vi) Registro Federal de Contribuyentes, vii) Identificación Oficial (credencial para votar INE., Pasaporte, cartilla Militar, Licencia de conducir, Cedula Profesional y/o cédula Migratoria), viii) Edad, ix) copia de comprobante de domicilio, x) (recibo de Cuenta Catastral, xi) copia de suministro de energía eléctrica y/o Servicio telefónico, los cuales se solicitarán con una antigüedad de tres meses), x) estado civil, xi) correo electrónico (en caso de contar con este), xii) número de teléfono particular, de trabajo, así como móvil , y extensión (en caso que aplique), xiii) Registro Federal de Contribuyentes, xiv) nombre completo de la persona que se designe como Titular Sustituto, xv) referencias personales, xvi) datos de localización y de contacto en caso de servicio o aclaración, xvii) datos de usuarios de los servicios, xviii) datos bancarios, (a fin de realizar los cargos que autorice, xix) todo lo relacionado con el objeto de la prestación del servicio objeto de la relación que con usted se cree, xx) información de Facturación conforme a la legislación vigente aplicable, xxi) información de representantes o apoderados
      </p>
      <p style={{ textAlign: "justify" }}>
        Para el caso de personas morales se recabará información conforme a Legislación vigente y aplicable, y en materia de datos la que corresponde a los representantes, factores y dependientes, como i) nombre completo, ii) identificación oficial, iii) testimonio en las que consten las facultades referidas, iv) cartas poder; se hace de su conocimiento que dentro de los documentos que se pueden requerir que contenga información de personas físicas es: a) Acta constitutiva registrada debidamente inscrita en el Registro Público de la Propiedad y Comercio, b) correo electrónico, c) número telefónico, Número Telefónico móvil, d) identificación oficial, e)comprobante Domicilio, f) actualización de datos por cualquier medio señalado.
      </p>
      <p style={{ textAlign: "justify" }}>
        La finalidad de la obtención de los datos de referencia es la de brindarle el servicio y los productos que solicita, ofrecerle la información sobre nuestros productos y servicios, así como de evaluación calidad de servicios, para él envió de publicidad o aclaraciones por correo electrónico y otros medios.
      </p>
      <p style={{ textAlign: "justify" }}>
        La recolección y tratamiento de datos personales para identificación de cliente consumidor ZONA DE VIDEOGRABACIÓN en las áreas en de acceso público se encuentran instalados por cámaras de seguridad de Grupo San Martín con domicilio RUBÍ SUR 836, ENTRE BLVD. ZAPATA Y RÍO HUMAYA, COLONIA GUADALUPE, CULIACÁN, SINALOA,C.P. 80220, las imágenes son captadas por este sistema de grabación serán utilizadas para la seguridad de las personas que nos vistan, empleados, para mayor información acerca del uso de datos personales puede consultar nuestro aviso de privacidad en la página https://www.sanmartin.com.mx/.
      </p>
      <p style={{ textAlign: "justify" }}>
        E) Medios automáticos para recabar datos personales: Le informamos que al acceder a las aplicaciones móviles y sitios web de https://www.sanmartin.com.mx/, es posible que situemos en su dispositivo algunos medios de rastreo tales como “cookies”. u otros, lo anterior permitirá a Grupo San Martín mejorar tanto la eficacia de sus sitios web y demás aplicaciones móviles, como la de sus actividades de promoción y comercialización.
      </p>
      <p style={{ textAlign: "justify" }}>
        Usted podrá deshabilitar el uso de éstos medios automáticos siguiendo el procedimiento establecido en su navegador de internet.
      </p>
      <p style={{ textAlign: "justify" }}>
        Cookies utilizadas:
      </p>
      <p style={{ textAlign: "justify" }}>
        <li>Cookies propias</li>
        <li>Cookies de terceros</li>
        <li>Cookies necesarias</li>
        <li>Cookies de rendimiento</li>
        <li>Cookies de funcionalidad</li>
        <li>Cookies de publicidad/objetivo</li>
      </p>
      <p style={{ textAlign: "justify" }}>
        <b>QUINTA.</b> TRATAMIENTO DE DATOS.- Los datos personales y/o datos personales sensibles en caso de ser proporcionados podrán ser tratados y utilizados, en los términos a que se refiere el art. 3 fracción XVIII de la Ley, por Grupo San Martín y/o todas o alguna de sus subsidiarias y/o todas o alguna de sus filiales como por terceros. Grupo San Martín, podrá realizar las transferencias de los datos para que sean tratados dentro y fuera del país, por personas distintas a que hace mención el art. 36 y siguientes de la Ley. La información que se transfiere se destinará a las mismas finalidades a que se hace mención en el punto tercero del Aviso. Así pues, en el presente acto, el titular, al no manifestar su oposición para la transferencia, acepta y está consciente que sus datos personales y/o datos personales sensibles serán transferidos en los términos que señala la Ley.
      </p>
      <p style={{ textAlign: "justify" }}>
        Grupo San Martín, no podrá comercializar los datos personales con terceros a efecto de que usted en calidad de titular lleve a cabo su contratación, se podrá transferir sus datos personales a nuestras sociedades, afiliadas o controladoras. Los datos personales sensibles en caso de ser proporcionados podrán ser tratados y utilizados, en los términos a que se refiere el art. 3 fracción XVIII de la Ley, por Grupo San Martín y/o todas o alguna de sus subsidiarias y/o todas o alguna de sus filiales como por terceros. Grupo San Martín, podrá realizar las transferencias de los datos para que sean tratados dentro y fuera del país, por personas distintas a que hace mención el art. 36 y siguientes de la Ley. La información que se transfiere se destinará a las mismas finalidades a que se hace mención en la cláusula tercera del Aviso.
      </p>
      <p style={{ textAlign: "justify" }}>
        De igual forma le informamos que Grupo San Martín, puede transferir sus datos personales o financieros con entidades diversas a las señaladas con anterioridad para celebrar el objeto de los productos y servicios contratados como son:
      </p>
      <p style={{ textAlign: "justify" }}>
        Su patrón: con la finalidad de retención a nómina en caso de que así lo haya contratado.
        <br />Su banco: en su caso para realizar los cobros de los servicios contratados con tarjeta de débito, crédito o cualquier otra.
        <br />Autoridades Diversas en la República Mexicana: Para el cumplimiento de las obligaciones que nos impone la Ley.
      </p>
      <p style={{ textAlign: "justify" }}>
        <b>SEXTA.</b> CONSENTIMIENTOS. Le informamos que en diversas acciones, como transferencia de datos sensibles, patrimoniales, financieros, se requiere su consentimiento expreso para transferir la información; en el supuesto de que usted no manifieste su oposición para la transferencia, acepta y está consciente que sus datos personales y/o datos personales sensibles serán transferidos en los términos que señala la Ley. De conformidad con lo que establece el artículo 8 de la ley, Todo tratamiento de datos personales estará sujeto al consentimiento de su titular, salvo las excepciones previstas por la Ley (último párrafo) el titular acepta y consiente todas y cada una de Los puntos del presente Aviso.
      </p>
      <p style={{ textAlign: "justify" }}>
        <b>SÉPTIMA.</b> MEDIOS PARA EL EJERCICIO DE LOS DERECHOS ARCO.- Usted tiene derecho a conocer qué datos personales tenemos de usted, para qué los utilizamos y las condiciones del uso que les damos (Acceso). Asimismo, es su derecho solicitar la corrección de su información personal en caso de que esté desactualizada, sea inexacta o incompleta (Rectificación); que la eliminemos de nuestros registros o bases de datos cuando considere que la misma no está siendo utilizada conforme a los principios, deberes y obligaciones previstas en la normativa (Cancelación); así como oponerse al uso de sus datos personales para fines específicos (Oposición). Estos derechos se conocen como derechos ARCO. Para el ejercicio de cualquiera de los derechos ARCO, usted deberá presentar la solicitud respectiva ante el Departamento de Datos Personales de Grupo San Martín, los derechos de Acceso, Rectificación, Cancelación y Oposición (ARCO), establecidos en la Ley, así como a limitar o cancelar el uso o divulgación, o revocar el consentimiento que para tal fin se haya otorgado, el titular debe enviar una solicitud por escrito donde se detallen los siguientes puntos:
      </p>
      <p style={{ textAlign: "justify" }}>
        Número, fecha y lugar de contrato
        <br />Nombre de titular, domicilio, números telefónicos, correo electrónico;
        <br />Documentos que acrediten la identidad de la persona que solicita, a nombre propio o en representación del titular, para el ejercicio de los derechos que consagra la Ley;
        <br />Descripción detallada de los motivos y razones por los cuales solicita el ejercicio de sus derechos.
        <br />Nombre completo y firma del solicitante.
        <br />La solicitud se dirigirá al Departamento de Atención al cliente, por escrito libre con el mínimo de requisitos señalados previamente, por nuestro formato de solicitud de Derechos ARCO, dicha solicitud se remitirá al Departamento de Atención al cliente de Grupo San Martín, a fin de que integrar la información correspondiente y darle la respuesta en el término de 20 días, conforme a lo establecido en la Ley y a través de conductos que usted autorice.
      </p>
      <p style={{ textAlign: "justify" }}>
        Grupo San Martín, pone a su disposición el Departamento de Atención al cliente, que tendrá a su cargo la recepción, registro y atención de sus Solicitudes para ejercer su derecho de acceso, rectificación, cancelación y oposición al tratamiento de sus datos personales, así como para limitar el uso o divulgación de sus datos, y los demás derechos Previstos en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares. Para cualquier solicitud, aclaración o comentario relacionada con el presente Avisó de Privacidad sírvase contactar a través de nuestra página web https://www.sanmartin.com.mx/.
      </p>
      <p style={{ textAlign: "justify" }}>
        De igual forma le informamos que usted puede revocar el consentimiento que, en su caso, nos haya otorgado para el tratamiento de sus datos personales. Sin embargo, es importante que tenga en cuenta que no en todos los casos podremos atender su solicitud o concluir el uso de forma inmediata, ya que es posible que por alguna obligación legal necesitemos seguir tratando sus datos personales. Asimismo, usted deberá considerar que para ciertos fines, la revocación de su consentimiento implica que no le podamos seguir prestando el servicio que nos solicitó, o la conclusión de su relación con nosotros.
      </p>
      <p style={{ textAlign: "justify" }}>
        <b>OCTAVA.</b> SEGURIDAD: Grupo San Martín, tomará las medidas de seguridad Técnicas y física, se utilizará una variedad tecnológica y administrativa para salvaguardar la información de datos personales contra de la alteración, divulgación, pérdida, destrucción.
      </p>
      <p style={{ textAlign: "justify" }}>
        LEGISLACIÓN APLICABLE: La interpretación y aplicación del presente Aviso de Privacidad se regirá por la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, y las demás leyes y disposiciones reglamentarias y administrativas que resulten aplicables. El presente Aviso de Privacidad tiene vigencia a partir de su fecha de publicación, según lo previsto en los artículos Tercero y Cuarto Transitorios de la Ley Federal de Protección de Datos Personales en Posesión Particulares.
      </p>
      <p style={{ textAlign: "justify" }}>
        <b>NOVENA:</b> El presente aviso de privacidad puede sufrir modificaciones, totales o parciales, cambios o actualizaciones derivadas de nuevos requerimientos legales; de nuestras propias necesidades por los productos o servicios que ofrecemos; de nuestras prácticas de privacidad; de cambios en nuestro modelo de negocio, o por otras causas. Nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir el presente aviso de privacidad, a través de nuestra página https://www.sanmartin.com.mx/.
      </p>
      <p style={{ textAlign: "justify" }}>
        <b>DÉCIMA.</b> CONSENTIMIENTO.- De conformidad con lo que establece el artículo 9 de la Ley, el titular acepta y consiente todos y cada uno de los puntos del presente Aviso, suscribiendo el presente.
      </p>
      <p style={{ textAlign: "justify" }}>
        Al interactuar a través de SMS. Whatsapp o cualquier otro mecanismo dispuesto, el usuario autoriza que se le puede contactar para brindar más información promocional o acerca de los productos y servicios que pueda tener o potencialmente adquirir con nosotros, adicionalmente el usuario podrá proporcionarnos su autorización mediante escritos, especialmente dispuestos para ello o mediante llamada de voz, a fin de que pueda ser contactado a través de cualquier otro medio que tenga disponible Grupo San Martín o para solicitar la baja.
      </p>
      <p style={{ textAlign: "justify" }}>
        Asimismo, los Datos Personales proporcionados por el Titular, con excepción de Datos Personales sensibles podrán ser utilizados para fines comerciales y promocionales, para el envío de información comercial y publicitaria, incluidos los envíos por correo electrónico, telefonía celular (mensaje SMS, MMS, entre otros) o todo medio de comunicación electrónica similar o que pueda llegar a desarrollarse, así como para encuestas de calidad y satisfacción, análisis de uso de productos, estadísticas para el envío de avisos acerca de productos y servicios operados por el Responsable y cuando otras Leyes lo requieran.
      </p>
      <p style={{ textAlign: "justify" }}>
        Última modificación: 13  de agosto del 2024.
      </p>
    </Box>
  );
}

export default AvisoPrivacidad;