import React from 'react';

// @material-ui/core components
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Hidden from '@material-ui/core/Hidden';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import PayTableMtto from './sections/PayTableMtto';

// Components
import HeaderBar from '../../components/HeaderBar';
import HeaderLinks from '../../components/PaymentHeaderLinks';
import TextView from '../../components/TextView';
import {
  consultarPagos,
  estadoDeCuenta,
  generarLinkDePago,
  consultarPagoRealizado,
  consultarContrato,
  consultarContratoMtto,
  ValidaPagoPendiente,
  ValidaPagoSinOrden,
  ValidaHorarioPago
} from "../../Api";
import { useWindowDimensions } from '../../App';

import { parseString } from 'xml2js';

const themeTable = createMuiTheme({
  palette: {
    primary: {
      main: '#35647D'
    },
    secondary: {
      main: '#326295',
    }
  }
});

async function getLinkDePago(user, rows, setLinkDePago, amountToPay) {
  if (!user || !rows) {
    return "PAGO DE CUOTA DE MANTENIMIENTO DE CONTRATO";
  }

  let keyPagos = rows.map(row => row).join(' ');

  const anio = rows.length > 1 ? 'AÑOS' : 'AÑO';
  let concepto = `PAGO DE CUOTA DE MANTENIMIENTO DE CONTRATO: ${user.folioContrato} ${anio} ${keyPagos}`;
  let aniosPagar=[];
  for ( let anios of user.listaAniosDebe) {
    for (let anio of rows) {
      if (anios.anio == anio) {
       await aniosPagar.push(anios)
      }
    }
  }
  let pagosData = {
    nombreCliente: user.nombre,
    flContrato: user.folioContrato,
    idContrato: user.idContrato,
    idCliente: user.idCliente,
    user: user,
    concepto: concepto,
    importe: amountToPay,
    pagos: aniosPagar
  }
  let jsonParcialidades = JSON.stringify(pagosData.pagos)
  
  let response = await generarLinkDePago(user.folioContrato, user.idContrato, user.idSistema, concepto, amountToPay, jsonParcialidades,'MTTO');
  //
  pagosData.link = response.data.ligaDeCobro;
  pagosData.id = response.data.idPago;
  pagosData.clave = response.data.clavePago;
  //
  localStorage.setItem('pagosData', JSON.stringify(pagosData));
  setLinkDePago(pagosData.link);

  setTimeout(() => {
    revisarPago();
  }, 5000);
}

async function revisarPago() {
  let pagosData = JSON.parse(localStorage.getItem('pagosData'));

  let response = await consultarPagoRealizado(pagosData.id, pagosData.clave, pagosData.token, pagosData.user, pagosData.pagos);

  if (!response.data.estatus) {
    setTimeout(() => {
      revisarPago();
    }, 1000);
    return;
  }

  pagosData.response = response.data;

  parseString(response.data.xmlRespuesta, function (err, result) {
    pagosData.response.xmlRespuesta = result;
  });
  parseString(response.data.xmlGenerado, function (err, result) {
    pagosData.response.xmlGenerado = result;
  });

  localStorage.setItem('pagosData', JSON.stringify(pagosData));
  window.location.href = '/payment/resume'
}

function PaymentDetailsPage({ showSnackbar, showCustomDialog }) {
  const { width } = useWindowDimensions();
  const cardMargin = width * 0.005;

  const userSession = JSON.parse(localStorage.getItem('user'));
  const [user, setUser] = React.useState(userSession ? userSession : {});
  const [linkDePago, setLinkDePago] = React.useState("");
  const [noPaga, setNoPaga] = React.useState(false);
  const [widths, setWidths] = React.useState('');
  const [mensajeNoPaga, setMensajeNoPaga] = React.useState("");
  const [imagen, setImagen] = React.useState("");

  const noPermitePago = (img, msj) => {
    setNoPaga(true);
    setImagen(img)
    setMensajeNoPaga(msj)
  }

  async function verificaContrato() {
    await consultarContratoMtto(user.folioContrato, user.idSistema).then(async (dataC) => {

      if ((dataC.data == null) /*|| (dataC.data.de_Estatus != "AUTORIZADO")*/) {
        closeSession();
      } else {

        let info = {}
        for (let key in dataC.data) {
          info[key] = dataC.data[key];
        }
        info.idSistema = user.idSistema
        info.idCliente = user.idCliente
        setUser(info)
        let dataU = info;
        await ValidaPagoPendiente(dataU.idContrato, dataU.idSistema).then(async (data) => {
          if (!data.data.result) {
            noPermitePago("/image/terminal 2.png", "Su pago está siendo procesado. Por favor, regrese en 24 horas para revisar el estado.")
          } else {
            await ValidaHorarioPago().then(async (data) => {
              if (!data) {
                const currentPath = window.location.pathname;
                localStorage.setItem("ReturnPage", currentPath)
                window.location.href = '/fueradeHorario'
              } else {
                await ValidaPagoSinOrden(dataU.idContrato, dataU.idSistema).then((data) => {
                  if (!data.data.result) {
                    noPermitePago("/image/OrdenPendiente.png", "Le informamos que su pago tiene una orden de pago activa.\n Para más información comuníquese con nuestro servicio de atención al cliente al número (667) 716-59-39 durante el horario de oficina.")
                  }
                });
              }
            })
          }
        });
      }
    });
  }
  
  React.useEffect(() => {
    if (!user || user.folioContrato == null || user.folioContrato === '' || user.folioContrato === '0' || !user.sesion) {
      closeSession();
    } else {
      verificaContrato(user.folioContrato)
    }
  }, []);

  return (
    <Box align={'center'}>
      {!userSession || !userSession.folioContrato || user.listaAniosDebe == undefined ?
        null
        :
        <React.Fragment>
          {!linkDePago ?
            <>
              <Box sx={{ width: { xs: .9, sm: 0.75, md: .5, lg: .4, xl: .3 } }} mx={cardMargin} mb={cardMargin} mt={cardMargin}>
                <PaymentAppBar username={user.nombre} />
                <Box mt={14}>
                  <table>
                    <tr style={{ borderBlockEnd: '1px' }}><td colSpan={2}><TextView variant="h6" fontWeight={400} align="left">{user.nombre}</TextView></td></tr>
                    <tr>
                      <td><TextView variant="h6" fontWeight={400} align="left">Contrato:</TextView></td>
                      <td><TextView variant="h6" fontWeight={400} align="rigth"><Box component="span" fontSize="subtitle1.fontSize">{user.folioContrato} </Box></TextView></td>
                    </tr>
                    <tr>
                      <td><TextView variant="h6" fontWeight={400} align="left">Parque:</TextView></td>
                      <td><TextView variant="h6" fontWeight={400} align="rigth"><Box component="span" fontSize="subtitle1.fontSize">{user.parque} </Box></TextView></td>
                    </tr>
                    <tr>
                      <td><TextView variant="h6" fontWeight={400} align="left">Descripción:</TextView></td>
                      <td><TextView variant="h6" fontWeight={400} align="rigth"><Box component="span" fontSize="subtitle1.fontSize">{user.descripcion} </Box></TextView></td>
                    </tr>
                    <tr>
                      <td><TextView variant="h6" fontWeight={400} align="left">Sección:</TextView></td>
                      <td><TextView variant="h6" fontWeight={400} align="rigth"><Box component="span" fontSize="subtitle1.fontSize">{user.seccion} </Box></TextView></td>
                    </tr>
                    <tr>
                      <td><TextView variant="h6" fontWeight={400} align="left">Cuadrante:</TextView></td>
                      <td><TextView variant="h6" fontWeight={400} align="rigth"><Box component="span" fontSize="subtitle1.fontSize">{user.cuadrante} </Box></TextView></td>
                    </tr>
                    <tr>
                      <td><TextView variant="h6" fontWeight={400} align="left">Ubicación:</TextView></td>
                      <td><TextView variant="h6" fontWeight={400} align="rigth"><Box component="span" fontSize="subtitle1.fontSize">{user.ubicacion} </Box></TextView></td>
                    </tr>
                  </table>
                  <Grid item container direction="column" justify="center" alignItems="center">
                    <Grid item container xs={12}>
                      <Box width={1} mx={cardMargin} mb={cardMargin} mt={cardMargin}>
                        <ThemeProvider theme={themeTable}>
                          <Card elevation={2} style={{ backgroundColor: "#ffffffcc", borderRadius: 12 }}>
                            <CardContent style={{ padding: 0 }}>
                              {noPaga ?
                                <Grid item container xs={11} direction="column" justifyContent="center" alignItems="center" spacing={2} style={{ width: '100%' }}>
                                  <img src={imagen} alt="Icono de mantenimiento" style={{ width: '120px', marginBottom: '20px' }} />
                                  <TextView variant="subtitle1" align="center" fontWeight={200}>
                                    <b>{mensajeNoPaga}</b>
                                  </TextView>
                                </Grid>
                                :
                                <>
                                  <PayTableMtto user={user} getLinkDePago={getLinkDePago} setLinkDePago={setLinkDePago} />
                                </>}
                            </CardContent>
                          </Card>
                        </ThemeProvider>
                      </Box>
                    </Grid>

                    <Hidden mdUp>
                      <Grid item container xs={12}>
                        <Box width={1} m={6} />
                      </Grid>
                    </Hidden>

                  </Grid>
                </Box>
              </Box></>
            :
            <Grid container direction="column" justify="flex-start" alignItems="flex-start" style={{ width: "100%" }}>
              <Grid item>
                <IconButton href="/payment/details/mtto">
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Grid item style={{ width: "100%" }}>
                <iframe src={linkDePago} width="100%" height="1024px" frameborder="0" scrolling="no" seamless="seamless"></iframe>
              </Grid>
            </Grid>
          }
        </React.Fragment>
      }
    </Box>
  );
}

function closeSession() {
  localStorage.clear();
  window.location.href = '/payment'
}

function PaymentAppBar({ username = "¿?" }) {
  return (<HeaderBar logo="/image/SMDark.png" rightLinks={<HeaderLinks closeSession={closeSession} />} fixed color="secondary" />);
}

export default PaymentDetailsPage;
